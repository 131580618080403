import moment from 'moment'

export const parseTime = (sec, hourText = 'hr', minText = 'min', plural = 's') => {
  if (!sec) {
    return `0${minText}`
  }
  const utc = moment.utc(sec * 1000)
  const hours = Number(utc.format('HH'))
  const min = Number(utc.format('mm'))

  let formatted = ''
  if (hours > 0) {
    formatted = `${hours}${hourText}`
    if (hours > 1) {
      formatted += plural
    }
    formatted += ' '
  }

  if (min > 0) {
    formatted += `${min}${minText}`
    if (min > 1) {
      formatted += plural
    }
  }

  return formatted
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  let newPhoneNumber = ''
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i % 2 === 0) {
      newPhoneNumber += ' '
    }
    newPhoneNumber += phoneNumber[i]
  }

  return newPhoneNumber
}

export const formatSearchQuery = (searchQuery) => {
  if (typeof searchQuery === 'object') {
    let querys = ''
    for (const queryInArray of searchQuery) {
      if (queryInArray.query && queryInArray.keyField !== 'dates') {
        if (queryInArray.operator) {
          querys += `${queryInArray.operator} ${queryInArray.keyField === 'all' ? '' : `${queryInArray.keyField}:`}${queryInArray.query} `
        } else {
          querys += `${queryInArray.keyField === 'all' ? '' : `${queryInArray.keyField}:`}${queryInArray.query} `
        }
      } else if (queryInArray.keyField === 'date') {
        querys += `${queryInArray.operator ? ` ${queryInArray.operator} ` : ''}${queryInArray.dates[0]}/${queryInArray.dates[1]}`
      }
    }
    return querys
  }
  return searchQuery
}
