import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/app.scss'

const SortButtons = ({ changeSorted, sorted, querySelected }) => {
  const { t } = useTranslation('resultsPage')
  const { search } = window.location
  const query = new URLSearchParams(search)
  const [isNoHidden, setIsNoHidden] = useState([])
  const [multiSearch, setMultiSearch] = useState()

  useEffect(() => {
    if (query.get('multisearch')) {
      setMultiSearch(true)
      setIsNoHidden([query.get('type')])
      changeSorted(query.get('type'))
    } else {
      setMultiSearch(false)
    }
  }, [querySelected, query.get('multisearch')])

  return (
    <div className="sortButtons">
      {multiSearch && querySelected?.searchEngine ? (
        <>
          {isNoHidden[0] && isNoHidden.map((motor) => (
            <button
              key={motor}
              type="button"
              className={sorted === motor ? 'sortButtons__btn sortButtons__btn--active' : 'sortButtons__btn'}
              onClick={() => {
                changeSorted(motor)
              }}
            >
              {t(`${motor}`)}
            </button>
          ))}
        </>
      ) : (
        <>
          <button
            type="button"
            className={sorted === 'pubmed' ? 'sortButtons__btn sortButtons__btn--active' : 'sortButtons__btn'}
            onClick={() => {
              changeSorted('pubmed')
            }}
          >
            {t('pubmet')}
          </button>
          {/*<button
            type="button"
            className={sorted === 'eds' ? 'sortButtons__btn sortButtons__btn--active' : 'sortButtons__btn'}
            onClick={() => {
              changeSorted('eds')
            }}
          >
            {t('eds')}
          </button>*/}
          <button
            type="button"
            className={sorted === 'espacenet' ? 'sortButtons__btn sortButtons__btn--active' : 'sortButtons__btn'}
            onClick={() => {
              changeSorted('espacenet')
            }}
          >
            {t('espacenet')}
          </button>
          <button
            type="button"
            className={sorted === 'ebscohost' ? 'sortButtons__btn sortButtons__btn--active' : 'sortButtons__btn'}
            onClick={() => {
              changeSorted('ebscohost')
            }}
          >
            {t('ebscohost')}
          </button>
        </>
      )}
    </div>
  )
}

SortButtons.propTypes = {
  sorted: PropTypes.string.isRequired,
  changeSorted: PropTypes.func.isRequired,
  querySelected: PropTypes.shape({
    searchEngine: PropTypes.string,
  }),
}

SortButtons.defaultProps = {
  querySelected: null,
}

export default SortButtons
