import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import usePrevious from '../../hooks/usePrevious'
import Header from '../Header'
import './styles/index.scss'

const Layout = ({ children, location }) => {
  const prevLocation = usePrevious({ location })
  const [hasUpdate, setHasUpdate] = useState(false)

  const { t } = useTranslation('header')

  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    const checkForUpdate = setInterval(() => {
      const updateFound = localStorage.getItem('update_found')
      if (updateFound === 'true') {
        setHasUpdate(true)
        localStorage.setItem('update_found', 'false')
      }
    }, 1000 * 60 * 1)
    return () => {
      clearInterval(checkForUpdate)
    }
  }, [])

  return (
    <>
      {!['/login', '/'].includes(location.pathname) && (
        <Header />
      )}
      { children }
      {hasUpdate && (
        <div className="appUpdate">
          {t('newVersion')}
          <button type="button" className="appUpdate__button" onClick={() => window.location.reload()}>{t('update')}</button>
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default withRouter(Layout)
