import axios from 'axios'
import config from '../config'
import { isUserTokenExpired } from '../lib/helpers'

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

const post = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, headers = {}) => {
  const res = await api({
    method: 'get',
    url: path,
    params,
    headers,
  })
  return res.data
}

const put = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const del = async (path, data = {}) => {
  const res = await api({
    method: 'delete',
    url: path,
    data,
  })
  return res.data
}

api.interceptors.request.use((cfg) => {
  const t = localStorage.getItem('token')
  if (isUserTokenExpired(t)) {
    localStorage.removeItem('token')
    window.location.href = '/login'
  }
  return cfg
}, (error) => { Promise.reject(error) })

const loginWithCredentials = async (login, password) => {
  const response = await post('/token', { login, password })
  return response
}

const resultsPubmet = async (value, limit, offset, sort) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    '/ws/searchs/pubmed',
    {
      terms: value,
      limit,
      offset,
      sort: sort || '',
    },
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const resultsSpacenet = async (value, limit, offset) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    '/ws/searchs/epo',
    {
      terms: value,
      limit,
      offset,
    },
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const resultsEbscohost = async (value, limit, offset) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    '/ws/searchs/ebscohost',
    {
      terms: value,
      limit,
      offset,
    },
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const resultsEds = async (value, limit, offset, limiter) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    '/ws/searchs/eds',
    {
      terms: value,
      limit,
      offset,
      limiter,
    },
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const findSpacenetDocById = async (value) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    '/ws/searchs/epo',
    {
      terms: `num=${value}`,
      limit: 1,
      offset: 0,
    },
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const scrapPubmed = async (id) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    `/ws/pubmed/${id}`,
    {},
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const getPublink = async (id) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    `/ws/pubmed/${id}/full-text-links`,
    {},
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

const shareDocumentWithUserGroup = async (community, urls, mails) => {
  const currentToken = localStorage.getItem('token')
  const body = {
    community,
    urls,
    type: ['documents'],
    recipients: mails,
  }
  const response = await post(
    '/ws/share',
    body,
    {
      authorization: `bearer ${currentToken}`,
      'content-type': 'application/json',
    },
  )
  return response
}

const shareSearchHistoryWithUserGroup = async (community, urls, mails) => {
  const currentToken = localStorage.getItem('token')
  const body = {
    community,
    urls,
    type: ['searchs'],
    recipients: mails,
  }
  const response = await post(
    '/ws/share',
    body,
    {
      authorization: `bearer ${currentToken}`,
      'content-type': 'application/json',
    },
  )
  return response
}

const fetchFiltersByMotor = async (motor) => {
  const currentToken = localStorage.getItem('token')
  const response = await get(
    `/ws/searchs/${motor}/capabilities`,
    {},
    {
      authorization: `bearer ${currentToken}`,
    },
  )
  return response
}

export default {
  get,
  post,
  put,
  del,
  loginWithCredentials,
  resultsPubmet,
  resultsSpacenet,
  resultsEbscohost,
  resultsEds,
  findSpacenetDocById,
  scrapPubmed,
  shareDocumentWithUserGroup,
  getPublink,
  shareSearchHistoryWithUserGroup,
  fetchFiltersByMotor,
}
