import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const CallbackPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const history = useHistory()

  useEffect(() => {
    if (query.get('token')) {
      localStorage.setItem('token', query.get('token'))
      history.push(localStorage.getItem('url') || '/')
      localStorage.removeItem('url')
    } else {
      history.push('/login')
    }
  }, [])

  return (
    <div />
  )
}

export default CallbackPage
