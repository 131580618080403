/* eslint-disable react/prop-types */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Picker from 'emoji-picker-react'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { io } from 'socket.io-client'
import swal from 'sweetalert'
import api from '../../api'
import config from '../../config'
import graphql from '../../graphql'
import { formatSearchQuery } from '../../lib/utils'
import '../../styles/app.scss'
import Message from '../Message'
import SearchChatItem from '../SearchChatItem'

const ChatSearchHistory = ({
  setResultsSelected,
  isCommunitiesPage,
  activeTab,
  setOpenUpdateCommunity,
  setOpenCollaboration,
  openCollaboration,
  communitySelected,
  setHistoriesSearchValue,
  historiesSearchValue,
}) => {
  const { t } = useTranslation('tchat')
  const [newMessage, setNewMessage] = useState()
  const [emojiOpen, setEmojiOpen] = useState(false)
  const history = useHistory()
  const [noCommunity, setNoCommunity] = useState(false)
  const { search } = window.location
  const query = new URLSearchParams(search)

  const [groupSelected, setGroupSelected] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [savedQueries, setSavedQueries] = useState([])
  const [previousGroupSelected, setPreviousGroupSelected] = useState()
  const [existingUserRating, setExistingUserRating] = useState(null)
  const [rating, setRating] = useState(0)
  const [avg, setAvg] = useState(0)

  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const userId = decoded.id

  const { webSocket, socketIoUrl } = config.SOCKET
  const [socket, setSocket] = useState()

  const { data: dataGroups } = useQuery(graphql.fetchGroups(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      idUser: userId,
    },
    onCompleted: () => {
      if (!groupSelected) {
        if (query.get('redirect') && query.get('redirect').includes('library')) {
          setGroupSelected(query.get('redirect').split('=')[1])
        } else if (query.get('redirect') && query.get('redirect').includes('feed')) {
          setGroupSelected(query.get('redirect').split('=')[1])
        } else if (query.get('group')) {
          setGroupSelected(query.get('group'))
        } else if (dataGroups.members.nodes[0]?.userByIdUser.idMainUserGroup) {
          setGroupSelected(dataGroups.members.nodes[0].userByIdUser.idMainUserGroup)
        } else if (dataGroups.members.nodes[0]) {
          setGroupSelected(dataGroups.members.nodes[0].userGroupByIdUserGroup.id)
        } else {
          setNoCommunity(true)
        }
      }
    },
  })

  const scrollMoveBottom = () => {
    const containerMessages = document.getElementById('scrollBottom')
    containerMessages.scrollTop = containerMessages.scrollHeight
  }

  const socketConnection = () => {
    let socketConnect = socket
    if (!socket) {
      socketConnect = io(webSocket, {
        query: {
          token: localStorage.getItem('token'),
        },
      })

      socketConnect.on('connect_error', (err) => {
        console.log(err instanceof Error) // true
        console.log(err.message) // not authorized
        console.log(err.data) // { content: "Please retry later" }

        if (err.message.indexOf('token') !== -1) {
          localStorage.removeItem('token')
          history.push('/login')
        }
      })
      socketConnect.on('connect', () => {
        console.log('socket connect')
        socketConnect.emit('joinChannel', {
          userGroupId: groupSelected,
          searchHistoryId: historiesSearchValue[0].id,
        })
      })

      socketConnect.on('message', (data) => {
        const newLI = document.createElement('div')
        const newLiDiv = document.createElement('div')
        newLI.appendChild(newLiDiv)
        newLiDiv.className = 'message'
        const pDate = document.createElement('p')
        pDate.className = 'message__date'
        pDate.appendChild(document.createTextNode(moment(new Date()).format('DD/MM/YYYY')))
        const pAuthor = document.createElement('p')
        newLiDiv.appendChild(pDate)
        newLiDiv.appendChild(pAuthor)
        const span = document.createElement('span')
        span.className = 'message__author'
        pAuthor.appendChild(span)
        span.appendChild(document.createTextNode(`${data.user.firstname[0]}. ${data.user.lastname.toLowerCase()} `))
        const spanContent = document.createElement('span')
        pAuthor.appendChild(spanContent)
        spanContent.appendChild(document.createTextNode(`: ${data.new_search_history_message.content}`))
        document.getElementById('socketMessages').appendChild(newLI)
        scrollMoveBottom()
      })
      setSocket(socketConnect)
    }
    try {
      socketConnect.emit('joinChannel', {
        userGroupId: groupSelected,
        searchHistoryId: historiesSearchValue[0].id,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const [createMessage, {
    error: errorCreatedMsg }] = useMutation(graphql.CREATE_MSG_SEARCH_HISTORY)

  const [createMessageStatus, {
    error: errorCreateMsgStatut }] = useMutation(graphql.CREATE_MSG_STATUS_HISTORY)

  const verifyReadMessage = (data) => {
    data.searchHistoryMessages.nodes.forEach((message) => {
      if (message.isRead === false) {
        try {
          createMessageStatus({
            variables: {
              idUser: userId,
              idUserGroup: groupSelected,
              idMessage: message.id,
            },
          })
        } catch (e) {
          console.log(errorCreateMsgStatut, e)
        }
      }
    })
  }

  const [fetchOldMessages, { error: errorFetchOldMsg,
    data: messagesByIdGroup }] = useLazyQuery(graphql.FETCH_MSG_BY_SEARCH_HISTORY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      socketConnection()
      scrollMoveBottom()
      verifyReadMessage(data)
    },
  })

  const [fetchRatingsSearchHistory, {
    data: ratingsByIdGroup, error: errorRatingsByIdGroup,
  }] = useLazyQuery(graphql.SEARCH_HISTORY_RATTINGS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      idUserGroup: groupSelected,
      idSearchHistory: historiesSearchValue[0].id,
      idUser: userId,
    },
    onCompleted: () => {
      if (ratingsByIdGroup?.searchHistoryRatings?.nodes.length > 0) {
        const ratingArray = ratingsByIdGroup.searchHistoryRatings.nodes
        setAvg(ratingArray.reduce((r, c) => r + c.value, 0) / ratingArray.length)

        const alreadyRated = ratingsByIdGroup.searchHistoryRatings.nodes
          .findIndex((rate) => rate.idUser === userId)
        if (alreadyRated !== -1) {
          setExistingUserRating(ratingsByIdGroup.searchHistoryRatings.nodes[alreadyRated])
          setRating(ratingsByIdGroup.searchHistoryRatings.nodes[alreadyRated].value)
        }
      } else {
        setRating(0)
        setAvg(0)
        setExistingUserRating(null)
      }
    },
  })

  const [createRatingSearchHistory] = useMutation(graphql.CREATE_SEARCH_HISTORY_RATING, {
    onCompleted: () => {
      fetchRatingsSearchHistory()
    },
  })
  const [updateRatingSearchHistory] = useMutation(graphql.UPDATE_SEARCH_HISTORY_RATING, {
    onCompleted: () => {
      fetchRatingsSearchHistory()
    },
  })

  const [deleteRatingSearchHistory] = useMutation(graphql.DELETE_SEARCH_HISTORY_RATING, {
    variables: {
      id: existingUserRating?.id,
    },
    onCompleted: () => {
      setExistingUserRating(null)
      setRating(0)
      fetchRatingsSearchHistory()
    },
  })

  const [fetchMembers, { data: membersData }] = useLazyQuery(graphql.fetchMembers(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      idUserGroup: groupSelected,
    },
  })

  const [createSearchHistorySharingInfo] = useMutation(graphql.CREATE_SEARCH_HISTORY_SHARING_INFO)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = socketIoUrl
    script.async = true
    document.body.appendChild(script)
    if (historiesSearchValue[0]) {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    if (activeTab === 'selectCommunityActive' || activeTab === 'communities') {
      if (!openCollaboration) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    }
  }, [openCollaboration])

  useEffect(() => {
    fetchMembers()
  }, [groupSelected])

  useEffect(() => {
    if (!noCommunity && isOpen && historiesSearchValue.length) {
      document.getElementById('socketMessages').innerHTML = ''
    }

    if (groupSelected && isOpen && historiesSearchValue.length === 1) {
      if (previousGroupSelected) {
        socket.emit('leaveChannel', {
          userGroupId: previousGroupSelected,
          searchHistoryId: historiesSearchValue[0].id,
        })
      }
      setPreviousGroupSelected(groupSelected)
      try {
        fetchRatingsSearchHistory()
      } catch (e) {
        console.log(e, errorRatingsByIdGroup)
      }
      try {
        fetchOldMessages({
          variables: {
            idUserGroup: groupSelected,
            idSearchHistory: historiesSearchValue[0].id,
            pIdUser: userId,
          },
        })
      } catch (e) {
        console.log(e, errorFetchOldMsg)
      }
    }
  }, [groupSelected, historiesSearchValue, isOpen])

  useEffect(() => {
    if (rating !== 0) {
      if (existingUserRating) {
        const alreadyRated = ratingsByIdGroup
          .searchHistoryRatings
          .nodes
          .find((rate) => rate.idUser === existingUserRating.idUser)
        if (rating !== alreadyRated.value) {
          updateRatingSearchHistory({
            variables: {
              id: alreadyRated.id,
              value: rating,
            },
          })
        }
      } else {
        createRatingSearchHistory({
          variables: {
            idUserGroup: groupSelected,
            idSearchHistory: historiesSearchValue[0].id,
            idUser: userId,
            value: rating,
          },
        })
      }
    }
  }, [rating])

  const fetchMessages = (idGroup) => {
    setGroupSelected(idGroup)
  }
  const updateSavedQueries = () => {
    if (savedQueries.findIndex((e) =>
      e.id === historiesSearchValue[0].id) === -1) {
      setSavedQueries([...savedQueries,
        { id: historiesSearchValue[0].id,
          userGroupsSearchHistoriesByIdSearchHistory: { nodes:
            [{ idUserGroup: groupSelected }],
          } }])
    } else {
      const docToUpdate = [...savedQueries]
      const docIndex = savedQueries.findIndex((e) => e.id === historiesSearchValue[0].id)
      const communities = docToUpdate[docIndex].userGroupsSearchHistoriesByIdSearchHistory.nodes
      docToUpdate[docIndex] =
      { id: historiesSearchValue[0].id,
        userGroupsSearchHistoriesByIdSearchHistory:
        { nodes: [...communities, { idUserGroup: groupSelected }] } }

      setSavedQueries(docToUpdate)
    }
  }

  useEffect(() => () => socket?.close(), [socket])

  const sendMessage = () => {
    if (newMessage) {
      try {
        const idGroup = groupSelected.toString()
        createMessage({
          variables: {
            idUserGroup: idGroup,
            idSearchHistory: historiesSearchValue[0].id,
            content: newMessage,
            idUser: userId,
          },
        })
        setNewMessage('')
      } catch (error) {
        console.log(error, errorCreatedMsg)
      }
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setNewMessage(
      newMessage +
      emojiObject.emoji,
    )
    setEmojiOpen(!emojiOpen)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessage()
    }
  }

  const [fetchIfSearchAlreadySaved, { data: dataSaved }] = useLazyQuery(
    graphql.fetchIfSearchAlreadySaved(), {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (dataSaved?.userGroupsSearchHistories?.nodes.length
        && savedQueries.findIndex((e) =>
          e.id === dataSaved?.userGroupsSearchHistories
            ?.nodes[0].searchHistoryByIdSearchHistory.id) === -1) {
          setSavedQueries([...savedQueries,
          dataSaved?.userGroupsSearchHistories?.nodes[0].searchHistoryByIdSearchHistory])
        }
      } },
  )

  useEffect(() => {
    fetchIfSearchAlreadySaved({
      variables: {
        idSearchHistory: historiesSearchValue[0]?.id,
      },
    })
  }, [historiesSearchValue])

  const checkIfCanBeSaved = (searchQuery) => {
    const savedIndex = savedQueries.findIndex((e) => e.id === searchQuery[0]?.id)
    let isSavedByThisGroup = false

    if (savedIndex !== -1) {
      isSavedByThisGroup = savedQueries[savedIndex].userGroupsSearchHistoriesByIdSearchHistory.nodes
        .findIndex((e) => e.idUserGroup === groupSelected) !== -1
    }
    return savedIndex === -1 || !isSavedByThisGroup
  }

  const saveSearchHistory = () => {
    createSearchHistorySharingInfo({
      variables: {
        idUser: userId,
        idUserGroup: groupSelected,
        idSearchHistory: historiesSearchValue[0].id,
      },
    })
    updateSavedQueries()
  }

  const formatEngine = (engine) => {
    switch (engine) {
      case 'PUB_MED':
        return 'pubmed'
      case 'EDS':
        return 'eds'
      case 'ESPACENET':
        return 'espacenet'
      case 'EBSCOHOST':
        return 'espacenet'
      case null:
        return t('src')
      default:
        break
    }
  }

  const getUrl = () => {
    if (typeof historiesSearchValue[0].searchedQuery === 'object' || historiesSearchValue[0].multi) {
      return `searchresult?multisearch=${historiesSearchValue[0].id}&type=${historiesSearchValue[0].searchEngine ? formatEngine(historiesSearchValue[0].searchEngine) : 'pubmed'}`
    }
    return `searchresult?value=${historiesSearchValue[0].searchedQuery}&history=${historiesSearchValue[0].id}`
  }

  const shareSearchHistory = () => {
    const nameGroup = dataGroups.members.nodes.filter(
      (group) => group.userGroupByIdUserGroup.id === groupSelected,
    )
    const urls = [{ url: `${config.API_BASE_URL}${getUrl()}`, title: formatSearchQuery(historiesSearchValue[0].searchedQuery) }]

    const community = nameGroup[0].userGroupByIdUserGroup.name
    const recipients = []
    membersData.members.edges.forEach((member) =>
      recipients.push(member.node.userByIdUser.email))
    swal({
      title: '',
      text: t('load'),
      icon: '/img/loading.gif',
      buttons: false,
      closeOnClickOutside: false,
    })
    api.shareSearchHistoryWithUserGroup(community, urls, recipients)
      .then(() => {
        swal(t('history.sendSuccess'), '', 'success')
        saveSearchHistory()
      })
      .catch(() => swal({
        icon: 'error',
        title: t('history.noSend'),
      }))
  }

  const openCollabInCommunitiesPage = () => {
    if (activeTab === 'selectCommunityActive') {
      setGroupSelected(communitySelected.userGroupByIdUserGroup.id)
    }
    setOpenUpdateCommunity(false)
    setOpenCollaboration(true)
  }

  const closeInCommunitiesTab = () => {
    setOpenCollaboration(false)
    setResultsSelected([])
    setOpenUpdateCommunity(true)
    if (activeTab === 'communities') {
      setGroupSelected({})
    }
  }

  if (!isOpen) {
    return (
      <>
        {isCommunitiesPage && !openCollaboration ? (
          <button
            className={`chatCloseCommunityPage ${activeTab === 'selectCommunityActive' ? 'chatCloseCommunityPage--positionTop' : 'chatCloseCommunityPage--positionBottom'}`}
            type="button"
            onClick={() => {
              openCollabInCommunitiesPage()
            }}
          >
            <img src="/img/picto-community.svg" alt={t('community')} />
            <p>{t('collaboration')}</p>
          </button>
        ) : (
          <button className="resultsPage__openChat" type="button" onClick={() => setIsOpen(true)}>
            <img src="/img/picto-community.svg" alt={t('community')} />
          </button>
        )}
      </>
    )
  }

  if (!noCommunity) {
    return (
      <aside
        style={
          {
            height: activeTab === 'selectCommunityActive' && 'calc(85vh - 115px)',
            position: activeTab === 'selectCommunityActive' && 'relative',
            width: activeTab === 'selectCommunityActive' && '100%',
            margin: activeTab === 'selectCommunityActive' && '0px',
          }
        }
        className={`resultsPage__chat ${activeTab === 'selectCommunityActive' && 'resultsPage__chat--conditionalOpenInCommunity'}`}
      >
        <div className="flex chatHeader">
          <div className="flex chatHeader__left">
            {activeTab === 'selectCommunityActive' || activeTab === 'communities' ? (
              <p className="chatHeader__commuTitle chatHeader__commuTitle--purple">{t('collaboration')}</p>
            ) : (
              <p className="chatHeader__commuTitle">{t('collaboration')}</p>
            )}
          </div>
          <button
            className="chatHeader__close"
            type="button"
            onClick={() => {
              setIsOpen(false)
              setHistoriesSearchValue([])
              if (isCommunitiesPage) {
                closeInCommunitiesTab()
              }
            }}
          >
            {activeTab === 'selectCommunityActive' ? (
              <img src="/img/picto-close-purple.svg" alt={t('close')} />
            ) : (
              <img src="/img/picto-close.svg" alt={t('close')} />
            )}
          </button>
        </div>
        <div className="resultsPage__groups">
          <select
            value={groupSelected}
            onChange={(e) => {
              fetchMessages(e.target.value)
            }}
            className="resultsPage__select"
          >
            {dataGroups?.members?.nodes.map((group) => (
              <option
                value={group.userGroupByIdUserGroup.id}
                key={group.userGroupByIdUserGroup.id}
              >
                {group.userGroupByIdUserGroup.name}
              </option>
            ))}
          </select>
        </div>
        <div className="resultsPage__selectedDocs">
          {historiesSearchValue && historiesSearchValue[0] && (
            <SearchChatItem
              displayShare={true}
              isSaveable={checkIfCanBeSaved(historiesSearchValue)}
              group={groupSelected}
              searchValue={historiesSearchValue}
              deleteSearchHistory={() =>
                setHistoriesSearchValue([])}
              share={() => shareSearchHistory()}
              save={() => saveSearchHistory(historiesSearchValue)}
              isDocPage={false}
            />
          )}
          {historiesSearchValue && historiesSearchValue.length < 2 && (
            <div className="ratingSearchHistory">
              <p className="ratingSearchHistory__title">{t('history.yourRating')}</p>
              {existingUserRating && (
                <button title={t('deleteRating')} onClick={() => deleteRatingSearchHistory()} type="button">
                  <img src="/img/picto-close.svg" alt={t('deleteRating')} />
                </button>
              )}
              <StarRatings
                rating={rating}
                starRatedColor="#8A7B60"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="0.5px"
                changeRating={setRating}
              />
              <p className="ratingSearchHistory__all">
                (
                {avg.toFixed(1)}
                )
              </p>
            </div>
          )}
        </div>
        <p className="resultsPage__chatTitle resultsPage__chatTitle--subtitle">{t('chat')}</p>
        <div className={emojiOpen ? 'resultsPage__containerChatEmojiOpen' : 'resultsPage__containerChat'} style={{ height: '62%' }}>
          <div className="resultsPage__containerMessages" id="scrollBottom">
            {messagesByIdGroup?.searchHistoryMessages?.nodes.map((message) => (
              <Message message={message} key={message.id} />
            ))}
            <div id="socketMessages" />
          </div>
          <input
            className="resultsPage__chatInput"
            type="text"
            placeholder={t('history.send')}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(event) => handleKeyPress(event)}
          />
          <button className="resultsPage__chatInput--emoji" type="button" onClick={() => setEmojiOpen(!emojiOpen)}>
            <img src="/img/picto_emoji.svg" alt="emoji" />
          </button>
          <button className="resultsPage__chatInput--submit" type="button" onClick={() => sendMessage()}>
            <img src="/img/picto_send.svg" alt={t('sendAlt')} />
          </button>
        </div>
        {emojiOpen && (
          <Picker
            native={true}
            onEmojiClick={onEmojiClick}
            disableSearchBar="false"
          />
        )}
      </aside>
    )
  }
  return (
    <aside className="resultsPage__chat">
      <div className="flex chatHeader">
        <div className="flex chatHeader__left">
          <p className="chatHeader__commuTitle">{t('community')}</p>
        </div>
        <button className="chatHeader__close" type="button" onClick={() => setIsOpen(false)}>
          <img src="/img/picto-close.svg" alt={t('close')} />
        </button>
      </div>
      <div className="nocommunity-message">
        <p>{t('noCommunity')}</p>
        <Link className="purple-btn flex" to="/feed">
          <p>{t('createCommunity')}</p>
        </Link>
      </div>
    </aside>
  )
}

ChatSearchHistory.propTypes = {
  setResultsSelected: PropTypes.func,
  isCommunitiesPage: PropTypes.bool,
  activeTab: PropTypes.string,
  setOpenUpdateCommunity: PropTypes.func,
  setOpenCollaboration: PropTypes.func,
  openCollaboration: PropTypes.bool,
  communitySelected: PropTypes.objectOf(PropTypes.any),
  setHistoriesSearchValue: PropTypes.func.isRequired,
  historiesSearchValue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

ChatSearchHistory.defaultProps = {
  setResultsSelected: null,
  setOpenUpdateCommunity: null,
  isCommunitiesPage: false,
  activeTab: '',
  setOpenCollaboration: null,
  openCollaboration: false,
  communitySelected: {},
}

export default ChatSearchHistory
