import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatSearchQuery } from '../../lib/utils'

import './styles/index.scss'

const NewMsgHistoryCard = ({ feed, activeTab, setHistoriesSearchValue }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY - HH:mm'))
    setInfoDate('')
  }, [])

  const formatEngine = (engine) => {
    switch (engine) {
      case 'PUB_MED':
        return 'pubmed'
      case 'EDS':
        return 'eds'
      case 'ESPACENET':
        return 'espacenet'
      case 'EBSCOHOST':
        return 'ebscohost'
      default:
        break
    }
  }

  const getUrl = () => {
    if (typeof feed.searchHistoryByIdSearchHistory.searchedQuery === 'object') {
      return `/searchresult?multisearch=${feed.searchHistoryByIdSearchHistory.id}&type=${feed.searchHistoryByIdSearchHistory.searchEngine ? formatEngine(feed.searchHistoryByIdSearchHistory.searchEngine) : 'pubmed'}`
    }
    return `/searchresult?value=${feed.searchHistoryByIdSearchHistory.searchedQuery}&history=${feed.searchHistoryByIdSearchHistory.id}`
  }

  return (
    <article className="memberMessageCommunity">
      {activeTab !== 'selectCommunityActive' && (<p className="memberMessageCommunity__title">{feed.userGroupByIdUserGroup.name}</p>)}
      <p className="memberMessageCommunity__date">{`${infoDate} ${dateCreated}`}</p>

      <div className="memberMessageCommunity__user">
        <div className="memberMessageCommunity__user--info">
          <div className="memberMessageCommunity__user--img" />
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '85%' }}>
            <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
            {t('sentNewMessage')}
            <strong title={formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)} style={{ textDecoration: 'underline' }}>{formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)}</strong>
          </p>
        </div>

      </div>
      <div className="memberMessageCommunity__msgContainer">
        {feed.content.length < 300 ? (
          <p>{`" ${feed.content} "`}</p>
        ) : (
          <p>{`" ${feed.content.substring(0, 300)}... "`}</p>
        )}
      </div>

      <div className="rateDocCommunity__buttons">
        <button
          className="memberMessageCommunity__buttons--purple"
          type="button"
          onClick={() =>
            setHistoriesSearchValue(
              [feed.searchHistoryByIdSearchHistory],
            )}
        >
          {t('openChat')}
        </button>
        <Link
          alt="link file"
          to={getUrl}
        >
          {t('openSearch')}
        </Link>
      </div>

    </article>
  )
}

NewMsgHistoryCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    documentByIdDocument: PropTypes.shape({
      engine: PropTypes.string,
      externalId: PropTypes.string,
      title: PropTypes.string,
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    searchHistoryByIdSearchHistory: PropTypes.shape({
      id: PropTypes.string,
      searchEngine: PropTypes.string,
      searchedQuery: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
      searchHistoryRatingsByIdSearchHistory: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.number,
        })),
      }),
    }),
  }),
  setHistoriesSearchValue: PropTypes.func.isRequired,
}

NewMsgHistoryCard.defaultProps = {
  feed: {},
}
export default NewMsgHistoryCard
