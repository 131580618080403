/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Redirect,
  Route, Router,
  Switch,
} from 'react-router-dom'
import { Layout, PrivateRoute } from './components'
import history from './lib/history'
import {
  CallBackPage,
  Document,
  Login,
  Profile,
  SearchPage,
  SearchResultPage,
} from './pages'
import './styles/app.scss'

export default function App() {
  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/" component={SearchPage} />
          <PrivateRoute path="/document/:type/:id" component={Document} />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/searchresult/" component={SearchResultPage} />
          <Route path="/callback/" component={CallBackPage} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="*" exact={true}>
            <Redirect to="/" />
          </PrivateRoute>
        </Switch>
      </Layout>
    </Router>
  )
}
