import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'

const FilterResults = (
  { sort,
    setSort,
    filter,
    setFilter,
    liveSearch,
    freefulltext,
    setFreefulltext,
    query,
    fetchResults,
    fetchResultsMultiQuerys,
  },
) => {
  const { t } = useTranslation('resultsPage')
  const yearNow = new Date().getFullYear()
  const dateInput = useRef(null)

  const handleClick = () => {
    dateInput.current.focus()
  }

  return (
    <div className="resultsPage__containerFilters">
      {liveSearch === 'pubmed' && (
      <div className="resultsPage__filterCategory--filterSortBy resultsPage__filterCategory">
        <label className="sortByLabel" htmlFor="sortby">{t('sortBy')}</label>
        <select
          id="sortby"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <option value="most recent">{t('date')}</option>
          <option value="author">{t('author')}</option>
          <option value="relevance">{t('relevance')}</option>
        </select>
      </div>
      )}
      <div className="resultsPage__filterCategory">
        <h6 className="resultsPage__filtersTitle">{t('filterByYear.title')}</h6>
        <div>
          <input
            value="0"
            type="radio"
            id="unspecified"
            name="year"
            checked={filter === '0'}
            onChange={(e) => setFilter(e.target.value)}
          />
          <label htmlFor="unspecified">{t('filterByYear.input1')}</label>
        </div>
        <div>
          <input
            type="radio"
            id={t('filterByDate.input1')}
            name="date"
            value="1"
            checked={filter.old === '1'}
            onChange={(e) => setFilter({ old: e.target.value })}
          />
          <label htmlFor={t('filterByDate.input1')}>{t('filterByDate.input1')}</label>
        </div>
        <div>
          <input
            type="radio"
            id={t('filterByDate.input2')}
            name="date"
            value="5"
            checked={filter.old === '5'}
            onChange={(e) => setFilter({ old: e.target.value })}
          />
          <label htmlFor={t('filterByDate.input2')}>{t('filterByDate.input2')}</label>
        </div>
        <div>
          <input
            type="radio"
            id={t('filterByDate.input3')}
            name="date"
            value="10"
            checked={filter.old === '10'}
            onChange={(e) => setFilter({ old: e.target.value })}
          />
          <label htmlFor={t('filterByDate.input3')}>{t('filterByDate.input3')}</label>
        </div>
        <div>
          <input
            type="radio"
            id={t('filterByYear.input5')}
            value="specificPeriod"
            checked={(filter === 'specificPeriod' || !!filter.from || !!filter.to) && filter !== '0'}
            onClick={handleClick}
            onChange={(e) => {
              setFilter(e.target.value)
            }}
          />
          <label htmlFor={t('filterByYear.input5')}>{t('filterByYear.input5')}</label>
        </div>
        <div>
          <input
            className="from-to"
            type="number"
            min="0"
            value={Object.prototype.hasOwnProperty.call(filter, 'from') && filter.from !== undefined ? filter.from : '1900'}
            ref={dateInput}
            max={yearNow}
            onChange={(e) => {
              const tmp = {
                to: filter.to,
                from: e.target.value,
              }
              setFilter(tmp)
            }}
            onBlur={() => {
              if (Object.prototype.hasOwnProperty.call(filter, 'from') && !filter.from) {
                const tmp = {
                  to: filter.to,
                  from: '1900',
                }
                setFilter(tmp)
              }
            }}
          />
          <input
            className="from-to"
            type="number"
            min="0"
            value={Object.prototype.hasOwnProperty.call(filter, 'to') && filter.to !== undefined ?
              filter.to : yearNow}
            max={yearNow}
            onChange={(e) => {
              const tmp = {
                from: filter.from,
                to: e.target.value,
              }
              setFilter(tmp)
            }}
            onBlur={() => {
              if (Object.prototype.hasOwnProperty.call(filter, 'to') && !filter.to) {
                const tmp = {
                  from: filter.from,
                  to: yearNow,
                }
                setFilter(tmp)
              }
            }}
          />
        </div>
      </div>
      {liveSearch === 'pubmed' && (
        <div className="resultsPage__filterCategory">
          <h6 className="resultsPage__filtersTitle">{t('filterTextAv.title')}</h6>
          <div>
            <input
              type="checkbox"
              id={t('filterTextAv.input2')}
              value="free full text[sb]"
              checked={freefulltext === 'free full text[sb]'}
              onClick={(e) => (freefulltext === null ?
                setFreefulltext(e.target.value) : setFreefulltext(null))}
            />
            <label htmlFor={t('filterTextAv.input2')}>{t('filterTextAv.input2')}</label>
          </div>
        </div>
      )}

      <div className="resultsPage__filterCategory">
        <button
          onClick={() => {
            if (query.get('multisearch')) {
              fetchResultsMultiQuerys()
            } else {
              fetchResults()
            }
          }}
          className="resultsPage__btnFilterEspacenet blue-btn"
          type="button"
        >
          {t('sort')}

        </button>
      </div>
    </div>
  )
}

FilterResults.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  setFilter: PropTypes.func.isRequired,
  liveSearch: PropTypes.string.isRequired,
  query: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  fetchResults: PropTypes.func.isRequired,
  fetchResultsMultiQuerys: PropTypes.func.isRequired,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  freefulltext: PropTypes.string,
  setFreefulltext: PropTypes.func,
}

FilterResults.defaultProps = {
  sort: '',
  setSort: null,
  freefulltext: '',
  setFreefulltext: null,
}

export default FilterResults
