/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import graphql from '../../graphql'
import '../../styles/app.scss'
import DocChatItem from '../DocChatItem'

const ChatDoc = ({
  resultsSelected,
  liveSearch,
  setResultsSelected,
  isDocPage,
}) => {
  const { t } = useTranslation('resultsPage')

  const [resultsWithDetails, setResultsWithDetails] = useState([])
  const [isOpen, setIsOpen] = useState(true)

  const [upsertDocument, { data: idIntDoc }] = useMutation(graphql.upsertDocument())

  useEffect(() => {
    if (resultsSelected.length) {
      const results = [...resultsSelected]
      results[results.length - 1].docId = idIntDoc?.upsertDocument?.uuid
      if (!results[results.length - 1].type) {
        if (liveSearch === 'pubmed' || liveSearch === 'eds') {
          results[results.length - 1].type = 'article'
        } else if (liveSearch === 'espacenet') {
          results[results.length - 1].type = 'patent'
        } else if (liveSearch === 'ebscohost') {
          results[results.length - 1].type = 'ebook'
        }
      }
      setResultsWithDetails(results)
    }
  }, [idIntDoc])

  useEffect(() => {
    if (resultsWithDetails.length) {
      const script = document.createElement('script')
      script.src = 'https://teams.microsoft.com/share/launcher.js'
      script.async = true
      script.defer = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [resultsWithDetails, isOpen])

  useEffect(() => {
    for (let index = 0; index < resultsSelected.length; index++) {
      const element = resultsSelected[index]
      if (element.doId) {
        upsertDocument({
          variables: {
            pAuthor: element.authors,
            pDocumentType: 'ARTICLE',
            pEngine: 'PUB_MED',
            pExternalId: element.id,
            pPublicationDate: element.epubDate || element.pubDate,
            pTitle: element.title,
          },
        })
      } else if (element.titles) {
        upsertDocument({
          variables: {
            pDocumentType: 'PATENT',
            pEngine: 'ESPACENET',
            pExternalId: element.id,
            pPublicationDate: element.pubDate,
            pTitle: element.titles?.en,
          },
        })
      } else if (element.ISBN) {
        upsertDocument({
          variables: {
            pAuthor: element.author,
            pDocumentType: 'EBOOK',
            pEngine: 'EBSCOHOST',
            pExternalId: element.id,
            pPublicationDate: element.pubDate,
            pTitle: element.title,
          },
        })
      } else {
        upsertDocument({
          variables: {
            pAuthor: element.author,
            pDocumentType: 'ARTICLE',
            pEngine: 'EDS',
            pExternalId: element.id,
            pPublicationDate: element.pubDate,
            pTitle: element.title,
          },
        })
      }
    }
    if (!resultsSelected.length) {
      setResultsWithDetails([])
    }

    setIsOpen(true)
  }, [resultsSelected])

  if (!isOpen) {
    return (
      <button className="resultsPage__openChat" type="button" onClick={() => setIsOpen(true)}>
        <img src="/img/picto-community.svg" alt={t('share')} />
      </button>
    )
  }

  return (
    <aside className="resultsPage__chat">
      <div className="flex chatHeader">
        <div className="flex chatHeader__left">
          <p className="chatHeader__commuTitle">{t('share')}</p>
        </div>
        <button
          className="chatHeader__close"
          type="button"
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <img src="/img/picto-close.svg" alt={t('close')} />
        </button>
      </div>
      <div className="resultsPage__selectedDocs">
        {resultsWithDetails.map((result) => (
          <div key={result.id}>
            {result.docId && (
            <DocChatItem
              key={result.docId}
              doc={result}
              deleteDoc={() =>
                setResultsSelected(resultsSelected.filter((el) => el.id !== result.id))}
              isDocPage={isDocPage}
            />
            )}
          </div>
        ))}
      </div>
      {resultsSelected.length === 0 && (
      <div className="resultsPage__noDoc">
        <img src="/img/doc-icon.svg" alt="" />
        <p>{t('noDoc')}</p>
      </div>
      )}
    </aside>
  )
}

ChatDoc.propTypes = {
  resultsSelected: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    titles: PropTypes.shape({
      en: PropTypes.string,
    }),
    author: PropTypes.string,
    authors: PropTypes.arrayOf(),
    id: PropTypes.string,
    pubDate: PropTypes.string,
    docId: PropTypes.string,
  })).isRequired,
  liveSearch: PropTypes.string,
  setResultsSelected: PropTypes.func,
  isDocPage: PropTypes.bool,
}

ChatDoc.defaultProps = {
  setResultsSelected: null,
  isDocPage: false,
  liveSearch: '',
}

export default ChatDoc
