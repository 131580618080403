/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles/app.scss'

const AdvancedSearchForm = ({
  close,
  changeValueQuerys,
  querySelected = null,
  singleValueInput,
}) => {
  const { t } = useTranslation('advancedSearch')
  const { search } = window.location
  const params = new URLSearchParams(search)
  const [motors] = useState(['all', 'pubmed', 'espacenet', 'ebscohost'])
  const [motorSelected, setMotorSelected] = useState(
    params.get('multisearch') ? params.get('type') || 'all' : 'all',
  )
  const [nbQuery, setNbQuery] = useState(1)

  const [dataFields] = useState(
    {
      all: {
        fields: [
          { key: 'all', value: '', implicitFields: [] },
          { key: 'title', value: '', implicitFields: [] },
          { key: 'author', value: '', implicitFields: [] },
          { key: 'abstract', value: '', implicitFields: [] },
          { key: 'date', value: '', implicitFields: [] },
        ],
        operators: [
          'AND',
        ],
      },
      pubmed: {
        fields: [
          { key: 'affiliation', value: 'affiliation', implicitFields: [] },
          { key: 'all', value: '', implicitFields: [] },
          {
            key: 'author',
            value: 'author',
            implicitFields: [
              'Author - Corporate',
              'Author - First',
              'Author - Identifier',
              'Author - Last',
              'Subject - Personal Name',
            ],
          },
          { key: 'date', value: 'date', implicitFields: [] },
          { key: 'full_text', value: 'text word', implicitFields: ['Supplementary Concept'] },
          { key: 'title', value: 'title', implicitFields: [] },
          { key: 'title/abstract', value: 'title/abstract', implicitFields: [] },
        ],
        operators: [
          'AND',
        ],
      },
      /*
      eds: {
        fields: [
          { key: 'abstract', value: 'AB' },
          { key: 'all', value: '' },
          { key: 'author', value: 'AU' },
          { key: 'title', value: 'TI' },
          { key: 'date', value: 'date' },
        ],
        operators: [
          'AND',
          'OR',
          'NOT',
        ],
      }, */
      espacenet: {
        fields: [
          { key: 'all', value: 'txt', implicitFields: [] },
          { key: 'applicant', value: 'pa', implicitFields: [] },
          { key: 'title', value: 'ti', implicitFields: [] },
          { key: 'inventor', value: 'in', implicitFields: [] },
          { key: 'title/abstract', value: 'ta', implicitFields: ['ab'] },
          { key: 'date', value: '', implicitFields: [] },
          {
            key: 'num',
            value: 'num',
            implicitFields: [
              'pn',
              'spn',
              'ap',
              'sap',
              'pr',
              'spr',
            ] },
        ],
        operators: [
          'AND',
          'OR',
        ],
      },
      ebscohost: {
        fields: [
          { key: 'all', value: 'TX', implicitFields: [] },
          { key: 'title', value: 'TI', implicitFields: [] },
          { key: 'author', value: 'AU', implicitFields: [] },
          { key: 'abstract', value: 'AB', implicitFields: [] },
          { key: 'full_text', value: 'TX', implicitFields: [] },
          { key: 'isbn', value: 'IB', implicitFields: [] },
        ],
        operators: [
          'AND',
          'OR',
          'NOT',
        ],
      },
    },
  )

  // default Querys
  const [querys, setQuerys] = useState([
    {
      id: 1,
      query: singleValueInput || '',
      keyField: 'all',
      field: '',
      implicitFields: [],
      dates: [null, null],
    },
  ])

  const addQuery = () => {
    const newQuerys = [
      ...querys,
      {
        id: querys[nbQuery - 1].id + 1,
        query: '',
        operator: dataFields[motorSelected].operators[0],
        keyField: 'all',
        field: '',
        implicitFields: [],
        dates: [null, null],
      },
    ]
    setQuerys(newQuerys)
    setNbQuery((prev) => prev + 1)
  }

  const removeQuery = (id) => {
    const newQuerys = querys.filter((q) => q.id !== id)
    setQuerys(newQuerys)
    setNbQuery((prev) => prev - 1)
    changeValueQuerys(newQuerys, motorSelected)
  }

  useEffect(() => {
    if (nbQuery === 0) {
      setQuerys([])
      setNbQuery(1)
      close()
    }
  }, [nbQuery, close])

  useEffect(() => {
    let defaultValue = singleValueInput
    if (querys[0]?.keyField === 'all') {
      defaultValue = querys[0].query
    }

    const itemAllByMotor = dataFields[motorSelected].fields.find((m) => m.key === 'all')
    if (!querySelected) {
      if (itemAllByMotor.value !== '') {
        setQuerys([
          {
            id: 1,
            dates: [null, null],
            query: defaultValue || '',
            keyField: 'all',
            implicitFields: itemAllByMotor.implicitFields,
            field: itemAllByMotor.value,
          },
        ])
      } else {
        setQuerys([
          {
            id: 1,
            query: defaultValue || '',
            keyField: 'all',
            field: '',
            implicitFields: [],
            dates: [null, null],
          },
        ])
      }
    }
    setNbQuery(querys.length)
  }, [motorSelected])

  const changeValue = (idQuery, key, value) => {
    const items = [...querys]

    switch (key) {
      case 'operator':
        items[idQuery].operator = value
        break
      case 'field':
        if (motorSelected === 'all') {
          items[idQuery].keyField = value
          items[idQuery].field = ''
          if (value === 'date') {
            items[idQuery].dates = [null, null]
          }
        } else {
          const itemChange = dataFields[motorSelected].fields.find((item) => item.value === value)
          items[idQuery].keyField = itemChange.key
          items[idQuery].field = value
          items[idQuery].implicitFields = itemChange.implicitFields
          if (value === 'date' || itemChange.key === 'date') {
            items[idQuery].dates = [null, null]
          }
        }
        break
      case 'query':
        items[idQuery].query = value
        break
      case 'date1':
        items[idQuery].dates = [value, items[idQuery].dates[1]]
        break
      case 'date2':
        items[idQuery].dates = [items[idQuery].dates[0], value]
        break
      default:
        break
    }
    setQuerys(items)
    if (!items[0].query && items[0].keyField !== 'date' && items[1]) {
      items[1].operator = ''
    }
    changeValueQuerys(items.filter((q) => q.query || q.keyField === 'date'), motorSelected)
  }

  useEffect(() => {
    if (window.location.pathname === '/searchresult') {
      if (querySelected) {
        if (querySelected.searchEngine === 'PUB_MED') {
          setMotorSelected('pubmed')
        } else if (querySelected.searchEngine === null) {
          setMotorSelected('all')
        } else if (querySelected.searchEngine === 'EBSCOHOST') {
          setMotorSelected('ebscohost')
        } else if (querySelected.searchEngine === 'EDS') {
          setMotorSelected('eds')
        } else {
          setMotorSelected('espacenet')
        }
        const newQuery = []
        for (const queryItem of querySelected.searchedQuery) {
          if (queryItem.operator) {
            newQuery.push({
              id: newQuery.length + 1,
              query: queryItem.query,
              operator: queryItem.operator,
              field: queryItem.field,
              keyField: queryItem.keyField,
              implicitFields: queryItem.implicitFields,
              dates: queryItem.dates,
            })
          } else {
            newQuery.push({
              id: newQuery.length + 1,
              query: queryItem.query,
              field: queryItem.field,
              keyField: queryItem.keyField,
              implicitFields: queryItem.implicitFields,
              dates: queryItem.dates,
            })
          }
        }
        setQuerys(newQuery)
        if (querySelected.searchedQuery.length > 1) {
          setNbQuery(querySelected.searchedQuery.length)
        }
      } else {
        changeValueQuerys(querys, motorSelected)
      }
    } else {
      changeValueQuerys(querys, motorSelected)
    }
  }, [querySelected])

  return (
    <main className="advancedSearchForm">
      <section>
        <p className="advancedSearchForm__title">{t('where')}</p>
        <div style={{ display: 'flex' }}>
          {motors && motors.map((motor) => (
            <div key={motor} className="advancedSearchForm__motor">
              <input
                name="motors"
                className="advancedSearchForm__motorInput"
                id="all"
                type="radio"
                checked={motorSelected === motor}
                onChange={() => {
                  setMotorSelected(motor)
                  changeValueQuerys(querys, motor)
                }}
              />
              <label className="advancedSearchForm__motorLabel" htmlFor="all">
                {t(`${motor}.title`)}
              </label>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div>
          <p className="advancedSearchForm__title">{t('searchValue')}</p>
          {!!querys.length && querys.map((q, index) => (
            <div key={q.id}>
              {querys[0].id === q.id ? (
                <div className="firstSearch">
                  <input
                    className="firstSearch__query"
                    type={q.keyField === 'date' ? 'date' : 'search'}
                    placeholder={t('placeholderQuery')}
                    value={q.keyField === 'date' ? q.dates[0] : q.query}
                    style={{ width: q.keyField === 'date' && '40%' }}
                    onChange={(e) => { q.keyField === 'date' ? changeValue(index, 'date1', e.target.value) : changeValue(index, 'query', e.target.value) }}
                    max={q.dates ? q.dates[1] : null}
                  />
                  {q.keyField === 'date' && (
                    <input
                      className="firstSearch__query"
                      type={q.keyField === 'date' ? 'date' : 'search'}
                      placeholder={t('placeholderQuery')}
                      value={q.dates[1]}
                      style={{ width: '40%' }}
                      onChange={(e) => { changeValue(index, 'date2', e.target.value) }}
                      min={q.dates ? q.dates[0] : null}
                    />
                  )}
                  <select
                    onChange={(e) => { changeValue(index, 'field', e.target.value) }}
                  >
                    {dataFields && dataFields[motorSelected].fields.map((field) => {
                      const existingKey = querys.find((e) => e.keyField === field.key)
                      if (existingKey && q.keyField !== existingKey.keyField && field.key !== 'all') {
                        return null
                      }
                      return (
                        <option
                          key={field.key}
                          id={field.key}
                          value={motorSelected === 'all' ? field.key : field.value}
                          selected={q.keyField === field.key}
                        >
                          {t(`${motorSelected}.fields.${field.key}`)}
                        </option>
                      )
                    })}
                  </select>
                  <div className="buttonsField">
                    <button
                      disabled={(index !== querys.length - 1) || (q.keyField !== 'date' && !q.query.trim()) || (q.keyField === 'date' && q.dates.includes(null))}
                      className="buttonsField__button"
                      type="button"
                      onClick={() => {
                        addQuery()
                      }}
                    >
                      <img src="/img/picto-close-purple.svg" alt="add new field" style={{ transform: 'rotate(46deg)' }} />
                    </button>
                    <button
                      disabled
                      className="buttonsField__button"
                      type="button"
                      onClick={() => removeQuery(q.id)}
                    >
                      <img src="/img/picto_remove-purple.svg" alt="remove field" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="firstSearch">
                  <select
                    className="firstSearch__selectFilter"
                    onChange={(e) => { changeValue(index, 'operator', e.target.value) }}
                  >
                    {dataFields && dataFields[motorSelected].operators.map((op) => (
                      <option
                        key={op}
                        value={op}
                        selected={q.operator === op}
                      >
                        {t(`${motorSelected}.operators.${op}`)}
                      </option>
                    ))}
                  </select>
                  <input
                    className="firstSearch__query"
                    type={q.keyField === 'date' ? 'date' : 'search'}
                    placeholder={t('placeholderQuery')}
                    defaultValue={q.keyField === 'date' ? q.dates[0] : q.query}
                    style={{ width: q.keyField === 'date' ? '40%' : 'calc(75% - 30px)' }}
                    onChange={(e) => { q.keyField === 'date' ? changeValue(index, 'date1', e.target.value) : changeValue(index, 'query', e.target.value) }}
                    max={q.dates ? q.dates[1] : null}
                  />
                  {q.keyField === 'date' && (
                    <input
                      className="firstSearch__query"
                      type="date"
                      placeholder={t('placeholderQuery')}
                      defaultValue={q.dates[1]}
                      style={{ width: '40%' }}
                      onChange={(e) => { changeValue(index, 'date2', e.target.value) }}
                      min={q.dates ? q.dates[0] : null}
                    />
                  )}
                  <select
                    onChange={(e) => { changeValue(index, 'field', e.target.value) }}
                  >
                    {dataFields && dataFields[motorSelected].fields.map((field) => {
                      const existingKey = querys.find((e) => e.keyField === field.key)
                      if (existingKey && q.keyField !== existingKey.keyField && field.key !== 'all') {
                        return null
                      }
                      return (
                        <option
                          key={field.key}
                          id={field.key}
                          value={motorSelected === 'all' ? field.key : field.value}
                          selected={q.keyField === field.key}
                        >
                          {t(`${motorSelected}.fields.${field.key}`)}
                        </option>
                      )
                    })}
                  </select>
                  <div className="buttonsField">
                    <button
                      disabled={(index !== querys.length - 1) || (q.keyField !== 'date' && !q.query.trim()) || (q.keyField === 'date' && q.dates.includes(null))}
                      className="buttonsField__button"
                      type="button"
                      onClick={() => {
                        addQuery()
                      }}
                    >
                      <img src="/img/picto-close-purple.svg" alt="add new field" style={{ transform: 'rotate(46deg)' }} />
                    </button>
                    <button
                      className="buttonsField__button"
                      type="button"
                      onClick={() => removeQuery(q.id)}
                    >
                      <img src="/img/picto_remove-purple.svg" alt="remove field" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

      </section>
    </main>
  )
}

AdvancedSearchForm.propTypes = {
  close: PropTypes.func.isRequired,
  changeValueQuerys: PropTypes.func.isRequired,
  querySelected: PropTypes.node,
  singleValueInput: PropTypes.string,
}

AdvancedSearchForm.defaultProps = {
  querySelected: [],
  singleValueInput: '',
}
export default AdvancedSearchForm
