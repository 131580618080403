import React from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MemberListItem } from '..'

const DetailUsersCommunity = ({
  users,
  community,
  close,
  isPrivate,
  userId,
  canAdministrate,
  deleteGroupMember,
  changeAdmin,
}) => {
  const { t } = useTranslation('community')
  return (
    <div className="allMembers">
      <h2>{community.name}</h2>
      <p className="allMembers__subtitle">{t('allMembers')}</p>
      {users?.members?.edges[0] ? (
        <div className="allMembers__containerMembers">
          {users.members.edges.map((user) => (
            <>
              <MemberListItem
                key={user.node.id}
                memberId={user.node.id}
                user={user.node.userByIdUser}
                deleteUser={deleteGroupMember}
                undeletable={
                  !isPrivate ||
                  user.node.userByIdUser.id === userId ||
                  user.node.userByIdUser.id ===
                    community.idAdmin
                }
                canAdministrate={
                  canAdministrate &&
                  !isPrivate &&
                  user.node.userByIdUser.id !== userId
                }
                setToAdmin={canAdministrate ? changeAdmin : null}
              />
            </>
          ))}
        </div>
      ) : (
        <p>{t('noRequests')}</p>
      )}

      <button className="manageRequests__close" type="button" onClick={close}>
        {t('close')}
      </button>
    </div>
  )
}

export default DetailUsersCommunity

DetailUsersCommunity.propTypes = {
  community: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    private: PropTypes.bool,
    idAdmin: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
  users: PropTypes.objectOf(PropTypes.shape()).isRequired,
  isPrivate: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  canAdministrate: PropTypes.bool.isRequired,
  deleteGroupMember: PropTypes.func.isRequired,
  changeAdmin: PropTypes.func,
}

DetailUsersCommunity.defaultProps = {
  changeAdmin: null,
}
