import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatSearchQuery } from '../../lib/utils'

import './styles/index.scss'

const ShareHistoryCard = ({ feed, activeTab, setHistoriesSearchValue }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY - HH:mm'))
    setInfoDate('')
  }, [])

  const formatEngine = (engine) => {
    switch (engine) {
      case 'PUB_MED':
        return 'pubmed'
      case 'EDS':
        return 'eds'
      case 'ESPACENET':
        return 'espacenet'
      case 'EBSCOHOST':
        return 'ebscohost'
      default:
        break
    }
  }

  const getUrl = () => {
    if (typeof feed.searchHistoryByIdSearchHistory.searchedQuery === 'object') {
      return `/searchresult?multisearch=${feed.searchHistoryByIdSearchHistory.id}&type=${feed.searchHistoryByIdSearchHistory.searchEngine ? formatEngine(feed.searchHistoryByIdSearchHistory.searchEngine) : 'pubmed'}`
    }
    return `/searchresult?value=${feed.searchHistoryByIdSearchHistory.searchedQuery}&history=${feed.searchHistoryByIdSearchHistory.id}`
  }

  return (
    <article className="sharingHistoryContainer">
      {activeTab !== 'selectCommunityActive' && (
        <p className="sharingHistoryContainer__title">{feed.userGroupByIdUserGroup.name}</p>
      )}
      <p className="sharingHistoryContainer__date">{`${infoDate} ${dateCreated}`}</p>
      <div className="sharingHistoryContainer__user">
        <div className="sharingHistoryContainer__user--img" />
        <div style={{ display: 'flex', width: '82%' }}>
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
            {t('sharedHistory')}
            <span
              title={formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)}
            >
              {formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)}
            </span>
          </p>
        </div>
      </div>
      <div className="rateDocCommunity__buttons">
        <button
          className="memberMessageCommunity__buttons--purple"
          type="button"
          onClick={() =>
            setHistoriesSearchValue(
              [feed.searchHistoryByIdSearchHistory],
            )}
        >
          {t('openChat')}
        </button>
        <Link to={`/library?group=${feed.userGroupByIdUserGroup.id}&type=SEARCH`} alt="link library page">{t('goLibrary')}</Link>
        <Link
          alt="link file"
          to={getUrl}
        >
          {t('openSearch')}
        </Link>
      </div>
    </article>
  )
}

ShareHistoryCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    searchHistoryByIdSearchHistory: PropTypes.shape({
      id: PropTypes.string,
      searchEngine: PropTypes.string,
      searchedQuery: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
    }),
  }),
  setHistoriesSearchValue: PropTypes.func.isRequired,
}
ShareHistoryCard.defaultProps = {
  feed: {},
}
export default ShareHistoryCard
