import { useLazyQuery } from '@apollo/client'
import * as dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import './styles/index.scss'

import { useTranslation } from 'react-i18next'
import graphql from '../../graphql'
import NewMsgCard from '../NewMsgCard'
import NewMsgHistoryCard from '../NewMsgHistoryCard'
import NewRateDocCard from '../NewRateDocCard'
import NewRateHistoryCard from '../NewRateHistoryCard'
import ShareDocCard from '../ShareDocCard/index'
import ShareHistoryCard from '../ShareHistoryCard'

const FeedCommunityActivity = ({
  communityChoice,
  activeTab,
  setFileSelected,
  refetchCommunityFeed,
  setRefetchCommunityFeed,
  setOpenUpdateCommunity,
  setOpenCollaboration,
  fileSelected,
  selectCommunity,
  setHistoriesSearchValue,
}) => {
  const { t } = useTranslation('feedCommunities')
  const [feedResults, setFeedResults] = useState(null)
  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const { id } = decoded
  const [fetchFeed, { data: feedData }] = useLazyQuery(graphql.FETCH_FEED_ALL_GROUP_USER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      idUser: id,
    },
    onCompleted: () => {
      if (feedData.getMyFeed.nodes && !communityChoice) {
        setFeedResults(feedData.getMyFeed.nodes)
      }
    },
  })

  const [fetchFeedByIdCommunity,
    { data: dataGroup }] = useLazyQuery(graphql.FETCH_FEED_BY_ID_GROUP, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      idUser: id,
    },
    onCompleted: () => {
      const array = dataGroup.userGroups.nodes[0].viewUserGroupFeedsByIdUserGroup.nodes.filter(
        (feed) => feed.idUser === id,
      )
      setFeedResults(array)
    },
  })

  useEffect(() => {
    if (activeTab === 'selectCommunityActive' && communityChoice) {
      fetchFeedByIdCommunity({
        variables: {
          id: communityChoice.userGroupByIdUserGroup.id,
        },
      })
    } else if (!communityChoice) {
      fetchFeed()
    }
    setRefetchCommunityFeed(false)
  }, [activeTab, communityChoice, refetchCommunityFeed])

  const openFileInChat = (item) => {
    setHistoriesSearchValue([])
    setOpenCollaboration(true)
    if (activeTab === 'selectCommunityActive') {
      setOpenUpdateCommunity(false)
    }
    const i = {
      ...item.documentByIdDocument,
    }

    const index = fileSelected.findIndex((f) => f.id === i.id)

    if (communityChoice) {
      if (activeTab === 'communities') {
        // multi group of community, if my Communities tab active
        if (communityChoice.userGroupByIdUserGroup.id !== item.userGroupByIdUserGroup.id) {
          // if new community, change community and reset file with new file
          selectCommunity(item)
          setFileSelected([i])
        } else if (index === -1) {
          // if no change community, push file. multi autorized
          setFileSelected([...fileSelected, i])
        }
      } else if (index === -1) {
        // if one community choice in navBar, no change community because no change.
        setFileSelected([...fileSelected, i])
      }
    } else {
      selectCommunity(item)
      setFileSelected([i])
    }
  }

  return (
    <section id="haut" className="feedCommunityActivity">
      {activeTab === 'communities' && (
        <p className="feedCommunityActivity__title">{t('feedCommunityActivity')}</p>
      )}
      {activeTab === 'selectCommunityActive' && (
        <p className="feedCommunityActivity__title">{`${communityChoice?.userGroupByIdUserGroup?.name} ${t('feed')}`}</p>
      )}
      {feedResults?.length ? (
        feedResults.map((feed) => (
          <div key={feed.id}>
            {feed.event === 'join_group' && (
              <article className="changeMembersCommunity">
                <p className="changeMembersCommunity__date">{`${dayjs(feed.createdAt).format('DD/MM/YY - HH:mm')}`}</p>
                <div className="changeMembersCommunity__container">
                  <div className="changeMembersCommunity__image" />
                  <p>
                    <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname} `}</strong>
                    {t('joined')}
                    <strong>{` ${feed.userGroupByIdUserGroup.name} !`}</strong>
                  </p>
                </div>
              </article>
            )}
            {feed.event === 'leave_group' && (
              <article className="changeMembersCommunity">
                <p className="memberMessageCommunity__date">{`${dayjs(feed.createdAt).format('DD/MM/YY - HH:mm')}`}</p>
                <div className="changeMembersCommunity__container">
                  <div className="changeMembersCommunity__image" />
                  <p>
                    <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname} `}</strong>
                    {t('left')}
                    <strong>{` ${feed.userGroupByIdUserGroup.name} !`}</strong>
                  </p>
                </div>
              </article>
            )}
            {feed.event === 'rate_document' && (
              <NewRateDocCard
                feed={feed}
                activeTab={activeTab}
                openFileInChat={openFileInChat}
              />
            )}
            {feed.event === 'rate_search_history' && (
              <NewRateHistoryCard
                feed={feed}
                activeTab={activeTab}
                setHistoriesSearchValue={setHistoriesSearchValue}
              />
            )}
            {feed.event === 'share_search_history' && (
              <ShareHistoryCard
                activeTab={activeTab}
                feed={feed}
                setHistoriesSearchValue={setHistoriesSearchValue}
              />
            )}
            {feed.event === 'share_document' && (
              <ShareDocCard
                activeTab={activeTab}
                feed={feed}
                openFileInChat={openFileInChat}
              />
            )}
            {feed.event === 'new_message' && (
              <NewMsgCard
                activeTab={activeTab}
                feed={feed}
                openFileInChat={openFileInChat}
              />
            )}
            {feed.event === 'new_search_history_message' && (
              <NewMsgHistoryCard
                activeTab={activeTab}
                feed={feed}
                setHistoriesSearchValue={setHistoriesSearchValue}
              />
            )}
          </div>
        ))
      ) : (
        <>
          {feedResults ? (
            <p>{t('noActivity')}</p>
          ) : (
            <div className="feedCommunityActivity__loading" />
          )}
        </>
      )}
    </section>

  )
}

FeedCommunityActivity.propTypes = {
  communityChoice: PropTypes.node,
  activeTab: PropTypes.string.isRequired,
  setFileSelected: PropTypes.func.isRequired,
  setOpenUpdateCommunity: PropTypes.func,
  setOpenCollaboration: PropTypes.func,
  selectCommunity: PropTypes.func.isRequired,
  fileSelected: PropTypes.node,
  setHistoriesSearchValue: PropTypes.node.isRequired,
  setRefetchCommunityFeed: PropTypes.func.isRequired,
  refetchCommunityFeed: PropTypes.bool.isRequired,
}
FeedCommunityActivity.defaultProps = {
  communityChoice: {},
  setOpenUpdateCommunity: null,
  setOpenCollaboration: null,
  fileSelected: [],
}

export default FeedCommunityActivity
