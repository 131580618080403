import React from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'
import { FeedCommunityActivity } from '..'

const FeedCommunities = ({
  community,
  activeTab,
  setFileSelected,
  setOpenCollaboration,
  setOpenUpdateCommunity,
  selectCommunity,
  refetchCommunityFeed,
  setRefetchCommunityFeed,
  fileSelected,
  setHistoriesSearchValue,
  historiesSearchValue,
}) => (
  <div className="feedCommunities">
    <FeedCommunityActivity
      communityChoice={community}
      refetchCommunityFeed={refetchCommunityFeed}
      setRefetchCommunityFeed={setRefetchCommunityFeed}
      activeTab={activeTab}
      setFileSelected={setFileSelected}
      setOpenUpdateCommunity={setOpenUpdateCommunity}
      setOpenCollaboration={setOpenCollaboration}
      selectCommunity={selectCommunity}
      fileSelected={fileSelected}
      setHistoriesSearchValue={setHistoriesSearchValue}
      historiesSearchValue={historiesSearchValue}
    />
  </div>

)

FeedCommunities.propTypes = {
  community: PropTypes.node,
  setOpenUpdateCommunity: PropTypes.func,
  setOpenCollaboration: PropTypes.func,
  activeTab: PropTypes.string.isRequired,
  setFileSelected: PropTypes.func.isRequired,
  selectCommunity: PropTypes.func.isRequired,
  fileSelected: PropTypes.node,
  setHistoriesSearchValue: PropTypes.node.isRequired,
  historiesSearchValue: PropTypes.func.isRequired,
  refetchCommunityFeed: PropTypes.bool.isRequired,
  setRefetchCommunityFeed: PropTypes.func.isRequired,
}

FeedCommunities.defaultProps = {
  community: null,
  setOpenUpdateCommunity: null,
  setOpenCollaboration: null,
  fileSelected: [],
}

export default FeedCommunities
