/* eslint-disable react/no-danger */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles/index.scss'

const DocChatItem = ({
  doc,
  deleteDoc,
  isDocPage,
}) => {
  const [isDeleted, setIsDeleted] = useState(false)

  const formatString = (str) => str
    .replace(/(\B)[^ ]*/g, (match) => (match.toLowerCase()))
    .replace(/^[^ ]/g, (match) => (match.toUpperCase()))

  const { t } = useTranslation('library')

  const getDocLink = () => {
    const { pathname, href, origin, search } = window.location
    if (isDocPage) {
      return href
    }
    let type = ''
    if (doc.type === 'article') {
      type = 'pubmed'
    } else if (type === 'patent') {
      type = 'espacenet'
    } else {
      type = 'ebscohost'
    }
    return `${origin}/document/${type}/${doc.id}?redirect=${pathname + search}`
  }

  return (
    <div className={`chat-list-item ${isDeleted ? 'bounceOut' : ''}`}>
      <div className="flex">
        <div className="flex left">
          <div>
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={() => {
                setIsDeleted(true)
                setTimeout(() => deleteDoc(), 900)
              }}
              disabled={isDocPage}
            />
          </div>
          <div className="doc-infos">
            <div className="head-txt">
              <p>
                {doc.documentType ? formatString(doc.documentType) : t(doc.type)}
              </p>
            </div>
            <p className="title" dangerouslySetInnerHTML={{ __html: doc.title || doc.titles?.en }} />
            <p className="date">{moment(doc.pubDate).format('LL')}</p>
          </div>
        </div>
        <div
          className="teams-share-button"
          data-msg-text={doc.title || doc.titles?.en}
          data-href={getDocLink()}
          data-preview="false"
        >
          <img src="/img/picto_share.svg" alt={t('share')} />
        </div>
      </div>
    </div>
  )
}

DocChatItem.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
    docId: PropTypes.string,
    documentType: PropTypes.string,
    type: PropTypes.string,
    externalId: PropTypes.string,
    title: PropTypes.string,
    titles: PropTypes.shape({
      en: PropTypes.string,
    }),
    pubDate: PropTypes.string,
    engine: PropTypes.string,
  }).isRequired,
  deleteDoc: PropTypes.func.isRequired,
  isDocPage: PropTypes.bool.isRequired,
}

export default DocChatItem
