import React, { useEffect } from 'react'
import config from '../../config'

const Login = () => {
  useEffect(() => { window.location.href = config.LOGIN }, [])
  return (
    <div />
  )
}

export default Login
