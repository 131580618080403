import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import Result from '../Result'
import './styles/index.scss'

const ScrollResults = (
  { results,
    isLoading,
    fetchNext,
    setHistoriesSearchValue,
    offset,
    liveSearch,
    resultsSelected,
    setResultsSelected,
    queries,
    errorSpacenet,
    hideDocs,
    hideUnselected,
    querySelected,
  },
) => {
  const { t } = useTranslation('resultsPage')

  let res = results?.items
  if (results?.items?.length > 0) {
    res = results.items.filter((item) => item.id !== null)
  }

  return (
    <div className="infiniteScroll">
      {!isLoading &&
        (res?.length ? (
          <InfiniteScroll
            dataLength={res.length}
            hasMore={res.length < results.total}
            loader={hideUnselected ? null : <div className="resultsPage__loading" />}
            next={() => {
              if (!hideUnselected) {
                fetchNext()
              }
            }}
            endMessage={<p className="resultsPage__noMore">{t('endResult')}</p>}
          >
            <div className="resultsPage__head" style={liveSearch === 'pubmed' ? { display: 'flex' } : null}>
              {liveSearch === 'pubmed' && (
                <label htmlFor="hide">
                  <input id="hide" type="checkbox" checked={hideUnselected} onChange={hideDocs} />
                  {t('hide')}
                </label>
              )}
              <p className="resultsPage__nbFound">
                {results.total}
                {t('resultsAchieved')}
              </p>
            </div>
            {res.map((result, key) => (
              !hideUnselected
              || hideUnselected && resultsSelected.some((el) => el.id === result.id) ? (
                <Result
                  setHistoriesSearchValue={setHistoriesSearchValue}
                  number={key + 1 + offset}
                  type={liveSearch}
                  resultsSelected={resultsSelected}
                  key={`${key + 1 + offset}`}
                  result={result}
                  queries={queries}
                  setResultsSelected={setResultsSelected}
                  querySelected={querySelected}
                />
                ) : null
            ))}
          </InfiniteScroll>
        ) : (
          <div className="resultsPage__noFound">
            {errorSpacenet && liveSearch === 'espacenet' ? (
              <p className="resultsPage__noFound--p">{t('requestPb')}</p>
            ) : (
              <p className="resultsPage__noFound--p">{t('noFound')}</p>
            )}
          </div>
        ))}
    </div>
  )
}

ScrollResults.propTypes = {
  results: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    total: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchNext: PropTypes.func.isRequired,
  setHistoriesSearchValue: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  liveSearch: PropTypes.string.isRequired,
  resultsSelected: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  setResultsSelected: PropTypes.func.isRequired,
  errorSpacenet: PropTypes.bool.isRequired,
  queries: PropTypes.string.isRequired,
  hideDocs: PropTypes.func.isRequired,
  hideUnselected: PropTypes.bool.isRequired,
  querySelected: PropTypes.arrayOf(PropTypes.shape({})),
}

ScrollResults.defaultProps = {
  querySelected: null,
}

export default ScrollResults
