import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import StarRatings from 'react-star-ratings'

import './styles/index.scss'

const NewRateDocCard = ({ feed, activeTab, openFileInChat }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    const date = new Date()
    if (dayjs(date).format('DD/MM/YY') === dayjs(feed.createdAt).format('DD/MM/YY')) {
      setInfoDate(t('today'))
      setDateCreated(dayjs(feed.createdAt).format('HH:mm'))
    } else if (
      (dayjs(date).subtract(1, 'day').format('DD/MM/YY')) === (dayjs(feed.createdAt).format('DD/MM/YY'))
    ) {
      setDateCreated(dayjs(feed.createdAt).format('HH:mm'))
      setInfoDate(t('yesterday'))
    } else {
      setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY'))
      setInfoDate('')
    }
  }, [])
  return (
    <article className="rateDocCommunity">
      {activeTab !== 'selectCommunityActive' && (<p className="rateDocCommunity__title">{feed.userGroupByIdUserGroup.name}</p>)}
      <p className="rateDocCommunity__date">{`${infoDate} ${dateCreated}`}</p>

      <div className="rateDocCommunity__firstInfo">
        <div className="rateDocCommunity__user">
          <div className="rateDocCommunity__user--img" />
          <div style={{ display: 'flex', width: '82%' }}>
            <p
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
              {t('ratingDoc')}
              <span title={feed.documentByIdDocument.title} className="rateDocCommunity__user--doc">{` ${feed.documentByIdDocument.title}`}</span>
            </p>
          </div>
        </div>

        <div className="rateDocCommunity__ratting">
          <p className="rateDocCommunity__ratting--bold">{t('rating')}</p>
          <StarRatings
            rating={Number(feed.content) || 0}
            starRatedColor="purple"
            numberOfStars={5}
            starDimension="12px"
            starSpacing="0.3px"
          />
          {feed?.documentByIdDocument?.ratingsByIdDocument?.nodes[0]?.value ? (
            <p className="rateDocCommunity__ratting--rate">{`(${feed.documentByIdDocument.ratingsByIdDocument.nodes[0].value.toFixed(1)})`}</p>
          ) : (
            <p className="rateDocCommunity__ratting--rate">{t('null')}</p>
          )}
        </div>
      </div>

      <div className="rateDocCommunity__buttons">
        <button
          className="memberMessageCommunity__buttons--purple"
          type="button"
          onClick={() => openFileInChat(feed)}
        >
          {t('openChat')}
        </button>
        <Link
          alt="link file"
          to={`/document/${feed.documentByIdDocument.engine.toLowerCase().replace('_', '')}/${feed.documentByIdDocument.externalId}?redirect=${window.location.pathname}?group=${feed.userGroupByIdUserGroup.id}&multi=${activeTab === 'communities' && true}`}
        >
          {t('openDoc')}
        </Link>
      </div>
    </article>
  )
}

NewRateDocCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    documentByIdDocument: PropTypes.shape({
      engine: PropTypes.string,
      externalId: PropTypes.string,
      title: PropTypes.string,
      ratingsByIdDocument: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.number,
        })),
      }),
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
  openFileInChat: PropTypes.func.isRequired,
}
NewRateDocCard.defaultProps = {
  feed: {},
}
export default NewRateDocCard
