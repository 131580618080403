import { useQuery } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import gql from '../../graphql'
import './styles/index.scss'

const Profile = () => {
  const { t } = useTranslation('profile')
  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const history = useHistory()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')

  const { refetch } = useQuery(gql.findUserByEmail(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      email: decoded.email,
    },
    onCompleted: (data) => {
      setFirstname(data.users.edges[0].node.firstname)
      setLastname(data.users.edges[0].node.lastname)
      setEmail(data.users.edges[0].node.email)
    },
  })

  return (
    <div className="profile">
      <div className="profile__infos">
        <h1>{t('profile')}</h1>
        <label htmlFor="firstname">
          {t('firstname')}
          <input
            id="firstname"
            name="firstname"
            type="text"
            value={firstname}
            disabled
          />
        </label>
        <label htmlFor="lastname">
          {t('lastname')}
          <input
            id="lastname"
            name="lastname"
            type="text"
            value={lastname}
            disabled
          />
        </label>
        <label htmlFor="email">
          {t('email')}
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            disabled
          />
        </label>
        <div className="profile__btnGroup">
          <button
            type="button"
            className="blue-btn-outline"
            onClick={() => {
              refetch()
              history.goBack()
            }}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Profile
