import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useClickAway } from 'use-click-away'
import './styles/index.scss'

const MemberListItem = ({
  user,
  memberId,
  deleteUser,
  acceptUser,
  undeletable,
  manageMode,
  setToAdmin,
  canAdministrate,
}) => {
  const [openMenu, setOpenMenu] = useState(false)
  const { t } = useTranslation('community')

  const ref = React.useRef(null)
  useClickAway(ref, () => {
    setOpenMenu(false)
  })

  return (
    <div className="memberItem flex">
      <div className="flex memberInfos">
        <div style={{ width: '20%', marginRight: '20px' }}>
          <div className="memberInfos__picture" />
        </div>
        <div style={{ width: '80%', textAlign: 'left' }}>
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="memberInfos__name">{`${user.firstname} ${user.lastname}`}</p>
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="memberInfos__email">{user.email}</p>
        </div>
      </div>
      <div className="flex">
        {manageMode && (
        <button type="button" onClick={() => acceptUser()}>
          <img src="/img/picto-check.svg" alt={t('accept')} />
        </button>
        )}
        {!undeletable && (
        <button type="button" onClick={() => deleteUser(memberId || user.id)} title={t('deleteFromCommunity')}>
          <img src="/img/blue-close.svg" alt={t('delete')} />
        </button>
        )}
        {canAdministrate && (
          <>
            <button type="button" onClick={() => setOpenMenu((prev) => !prev)} title={t('deleteFromCommunity')}>
              <img src="/img/kebab-menu.svg" alt={t('delete')} />
            </button>
            {openMenu && (
              <div ref={ref} className="memberItem__menu">
                <button type="button" onClick={() => deleteUser(memberId || user.id)} title={t('deleteFromCommunity')}>
                  <p>{t('exclude')}</p>
                </button>
                <button type="button" onClick={() => setToAdmin(user.id)} title={t('deleteFromCommunity')}>
                  <p>{t('setToAdmin')}</p>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

MemberListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }).isRequired,
  deleteUser: PropTypes.func.isRequired,
  acceptUser: PropTypes.func,
  memberId: PropTypes.string,
  undeletable: PropTypes.bool,
  manageMode: PropTypes.bool,
  setToAdmin: PropTypes.func,
  canAdministrate: PropTypes.bool,
}

MemberListItem.defaultProps = {
  memberId: null,
  undeletable: false,
  manageMode: false,
  acceptUser: null,
  setToAdmin: null,
  canAdministrate: false,
}

export default MemberListItem
