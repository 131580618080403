import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import ChatDoc from '../../components/ChatDoc'
import graphql from '../../graphql'
import './styles/index.scss'
import './styles/pubmed.scss'

const Document = () => {
  const { type, id } = useParams()
  const [selectedDoc, setSelectedDoc] = useState([])
  const [pubmedLink, setPubmedLink] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()
  const { t } = useTranslation('library')
  const { search } = window.location
  const query = new URLSearchParams(search)

  const highlight = (text) => {
    const regex = new RegExp(`(${text})`, 'ig')
    if (type !== 'pubmed') {
      const inputText = document.getElementsByClassName('documentArea')[0]
      if (inputText) {
        inputText.innerHTML = inputText.innerHTML?.replace(regex, '<span class="highlight">$1</span>')
      }
    } else {
      const title = document.getElementsByClassName('heading-title')[0]
      const doc = document.getElementById('abstract')
      title.innerHTML = title.innerHTML.replace(regex, '<span class="highlight">$1</span>')
      if (doc) {
        doc.innerHTML = doc.innerHTML.replace(regex, '<span class="highlight">$1</span>')
      }
    }
  }

  const [fetchHistorySearchById] = useLazyQuery(graphql.SEARCHHISTORY_BY_ID, {
    onCompleted: (data) => {
      const { searchedQuery } = data.searchHistory
      searchedQuery.forEach((e) => highlight(e.query))
    },
  })

  const textToHighlight = () => {
    const redirectParams = query.get('redirect')
    const regexp = /(?:.*)value=(.*?)(?:&.*|$)/
    const regexpMultisearch = /(?:.*)multisearch=(.*?)(?:&.*|$)/
    const groups = regexp.exec(redirectParams) || regexpMultisearch.exec(redirectParams)
    const value = groups && groups[1]
    if (value && !groups[0].includes('multisearch')) {
      highlight(value)
    } else if (value && groups[0].includes('multisearch')) {
      fetchHistorySearchById({
        variables: { id: value } })
    }
  }

  const changePubmedPage = () => {
    const links = document.getElementById('pubmed-article').getElementsByTagName('a')
    const linksToChange = Array.from(links).filter((link) => {
      if (link.href.includes('localhost') || link.href.includes('lesaffre') || link.href.includes('metari')) {
        return true
      }
      return false
    })
    for (let index = 0; index < linksToChange.length; index++) {
      const link = linksToChange[index]
      const endlink = link.href.split('/')[3]
      link.setAttribute('target', '_blank')
      link.href = `https://pubmed.ncbi.nlm.nih.gov/${endlink}`
    }

    if (document.getElementById('toggle-authors')) {
      document.getElementById('toggle-authors').onclick = () => {
        if (getComputedStyle(document.getElementById('expanded-authors')).getPropertyValue('display') === 'none') {
          document.getElementById('expanded-authors').style.setProperty('display', 'unset')
        } else {
          document.getElementById('expanded-authors').style.setProperty('display', 'none')
        }
      }
    }
    textToHighlight()
  }
  useEffect(() => {
    if (type === 'espacenet') {
      api.findSpacenetDocById(id)
        .then((data) => {
          setSelectedDoc([data.items[0]])
        })
        .then(() => setTimeout(() => { textToHighlight() }, 2000))
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.setItem('url', window.location.pathname + window.location.search)
            history.push('/login')
          } else {
            setError(true)
          }
        })
    } else if (type === 'pubmed') {
      setLoading(true)
      api.resultsPubmet(id, 1, 0)
        .then((data) => setSelectedDoc([data.items[0]]))
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.setItem('url', window.location.pathname + window.location.search)
            history.push('/login')
          } else {
            setError(true)
          }
        })
      api.scrapPubmed(id)
        .then((data) => {
          if (document.getElementById('pb-article')) {
            document.getElementById('pb-article').innerHTML = data
            setLoading(false)
            changePubmedPage()
          }
        }).then(
          api.getPublink(id)
            .then((data) => {
              setPubmedLink(data)
            })
            .catch(() => setError(true)),
        ).catch(() => setError(true))
    } else if (type === 'ebscohost') {
      api.resultsEbscohost(id, 1, 0)
        .then((data) => setSelectedDoc([data.items[0]]))
        .then(() => textToHighlight())
        .catch((e) => {
          if (e?.response?.status === 401) {
            localStorage.setItem('url', window.location.pathname + window.location.search)
            history.push('/login')
          } else {
            setError(true)
          }
        })
    } else if (type === 'eds') {
      api.resultsEds(id, 1, 0)
        .then((data) => setSelectedDoc([data.items[0]]))
        .then(() => textToHighlight())
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.setItem('url', window.location.pathname + window.location.search)
            history.push('/login')
          }
        })
    }
  }, [])

  return (
    <div className="document">
      <div className="document__containerIframe">
        <div className="documentArea">
          {error ? (
            <div id="pubmed-article">
              <div id="pb-article">
                <p className="document__error">{t('error')}</p>
              </div>
            </div>
          ) : (
            <>
              {type === 'pubmed' && (
              <div id="pubmed-article">
                <div id="pb-article">
                  {error ? (
                    <p className="document__error">{t('error')}</p>
                  ) : (
                    <div className="loading" />
                  )}
                </div>
                <div id="publink">
                  {pubmedLink && !loading && (
                  <p className="textlink">{t('textlink')}</p>
                  )}
                  {!loading && pubmedLink?.map((textlink) => (
                    <a href={textlink.link} target="_blank" rel="noreferrer">
                      <img src={textlink.img} alt="" />
                    </a>
                  ))}
                </div>
              </div>
              )}
              {type === 'espacenet' && (
              <iframe
                title="espacenetdoc"
                src={`https://worldwide.espacenet.com/publicationDetails/biblio?locale=en_EP&II=0&CC=${id.substring(0, 2)}&NR=${id.substring(2)}`}
              />
              )}
              {type === 'eds' && selectedDoc.length && (
              <iframe
                title="edsdoc"
                src={`https://doi.org/${selectedDoc[0]?.id}`}
              />
              )}
              {type === 'ebscohost' && selectedDoc.length > 0 && (
              <div className="ebscohost">
                <div className="flex">
                  <img className="book-cover" src={`http://rps2images.ebscohost.com/rpsweb/othumb?id=NL$${id}$PDF&s=d`} alt={selectedDoc.title} onError={(e) => { e.target.hidden = true }} />
                  <div>
                    <a className="ebook-link" href={selectedDoc[0].link} target="_blank" rel="noreferrer">{selectedDoc[0].title}</a>
                    <p>{selectedDoc[0].author}</p>
                    <p className="ebook-info">
                      {'ISBN : '}
                      {selectedDoc[0].ISBN}
                      {' '}
                      -
                      {' '}
                      {selectedDoc[0].edition}
                    </p>
                  </div>
                </div>
                <p className="abstract">{selectedDoc[0].abstract}</p>
              </div>
              )}
            </>
          )}
        </div>
        <button
          className="closebtn"
          type="button"
          onClick={() => {
            history.push(search.replace('?redirect=', '')); return false
          }}
        >
          <img className="document__btnClose" src="/img/blue-close.svg" alt={t('close')} />
        </button>
      </div>
      <ChatDoc
        resultsSelected={selectedDoc}
        liveSearch={type}
        setResultsSelected={setSelectedDoc}
        isDocPage={true}
      />

    </div>
  )
}

export default Document
