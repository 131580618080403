/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import { ChatDoc, FilterResults, History, ScrollResults, SortButtons } from '../../components'
import AdvancedSearchForm from '../../components/AvancedSearchForm'
import graphql from '../../graphql'
import '../../styles/app.scss'

const SearchResultPage = () => {
  const { search } = window.location
  const query = new URLSearchParams(search)

  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const { id } = decoded

  const { t } = useTranslation('resultsPage')
  const [liveSearch, setLiveSearch] = useState(query.get('type') || 'pubmed')
  const [valueInput, setValueInput] = useState(query.get('value') || '')
  const [valueChange, setValueChange] = useState()

  // advanced serach states *********************************************
  const [openSearchAdvanced, setOpenSearchAdvanced] = useState(false)
  const [jsonQueryAdvanced, setJsonQueryAdvanced] = useState([])
  const [querySelected, setQuerySelected] = useState(null)
  const [motorFilterAdvancedQuerySelected, setMotorAdvancedQuerySelected] = useState()
  const [valuesKeyAllMotors] = useState(
    {
      all: { pubmed: '', espacenet: 'txt', ebscohost: 'TX', eds: '' },
      title: { pubmed: 'title', espacenet: 'ti', ebscohost: 'TI', eds: 'TI' },
      author: { pubmed: 'author', espacenet: 'in', ebscohost: 'AU', eds: 'AU' },
      abstract: { pubmed: 'Title/Abstract', espacenet: 'ta', ebscohost: 'AB', eds: 'AB' },
      date: { pubmed: 'date', espacenet: 'date', ebscohost: 'date', eds: 'date' },
    },
  )
  // **********************************************************************

  const [resultsPubmet, setResultsPubmet] = useState({})
  const [resultsEds, setResultsEds] = useState({})
  const [resultsSpacenet, setResultsSpacenet] = useState({})
  const [resultsEbscohost, setResultsEbscohost] = useState({})
  const [resultsSelected, setResultsSelected] = useState([])
  const [loadingPubmed, setLoadingPubmed] = useState(true)
  const [loadingEds, setLoadingEds] = useState(true)
  const [loadingSpacenet, setLoadingSpacenet] = useState(true)
  const [loadingEbscohost, setLoadingEbscohost] = useState(true)
  const [errorSpacenet, setErrorSpacenet] = useState(false)
  const [historiesSearchValue, setHistoriesSearchValue] = useState([])
  const [hideUnselected, setHideUnselected] = useState(false)

  const getFilters = () => {
    let filter = '0'
    if (query.get('old')) {
      filter = { old: query.get('old') }
    } else if (query.get('from')) {
      filter = { from: query.get('from'), to: query.get('to') }
    } else if (query.get('year')) {
      filter = query.get('year')
    }
    return filter
  }
  const [filterPubmed, setFilterPubmed] = useState(query.get('type') === 'pubmed' || query.get('type') === 'all' ? getFilters : '0')
  const [freefulltext, setFreefulltext] = useState(query.get('freefulltext') !== 'null' ? query.get('freefulltext') : null)
  const [pubmedSort, setPubmedSort] = useState(query.get('sort') || '')

  const [filterEspacenet, setFilterEspacenet] = useState(query.get('type') === 'espacenet' ? getFilters : '0')
  const [filterEds, setFilterEds] = useState(query.get('type') === 'eds' ? getFilters : '0')

  const yearNow = new Date().getFullYear()

  const [pubmedOffset, setPubmedOffset] = useState(50)
  const [edsOffset, setEdsOffset] = useState(50)
  const [espacenetOffset, setEspacenetOffset] = useState(50)
  const [ebscohostOffset, setEbscohostOffset] = useState(50)
  const [queries, setQueries] = useState('')
  const [openHistoric, setOpenHistoric] = useState(false)

  const limit = 50
  const offset = 0

  const results = () => {
    switch (liveSearch) {
      case 'pubmed':
        return resultsPubmet
      case 'espacenet':
        return resultsSpacenet
      case 'ebscohost':
        return resultsEbscohost
      case 'eds':
        return resultsEds
      default:
        return resultsPubmet
    }
  }

  const filters = () => {
    switch (liveSearch) {
      case 'pubmed':
        return filterPubmed
      case 'espacenet':
        return filterEspacenet
      case 'ebscohost':
        return null
      case 'eds':
        return filterEds
      default:
        return filterPubmed
    }
  }

  const isLoading = () => {
    switch (liveSearch) {
      case 'pubmed':
        return loadingPubmed
      case 'espacenet':
        return loadingSpacenet
      case 'ebscohost':
        return loadingEbscohost
      case 'eds':
        return loadingEds
      default:
        return loadingPubmed
    }
  }

  const { data: dataHistoriesSearch, refetch } = useQuery(
    graphql.SEARCH_HISTORIES_BY_ID_USER,
    {
      fetchPolicy: 'network-only',
      variables: {
        idUser: id,
      },
    },
  )

  const [fetchHistorySearchById, {
    data: historyData }] = useLazyQuery(graphql.SEARCHHISTORY_BY_ID, {
    onCompleted: () => {
      if (historyData) {
        setMotorAdvancedQuerySelected(
          historyData?.searchHistory?.searchEngine || null,
        )
        const items = historyData?.searchHistory?.searchedQuery
        if (typeof items === 'object') {
          setJsonQueryAdvanced(items)
          let querys = ''
          items.forEach((q) => {
            if (q.keyField !== 'date') {
              if (q.operator) {
                querys += ` ${q.operator} ${q.keyField === 'all' ? '' : `${q.keyField}:`}${q.query}`
              } else {
                querys += `${q.keyField === 'all' ? '' : `${q.keyField}:`}${q.query}`
              }
            } else {
              querys += `${q.operator ? ` ${q.operator} ` : ''}${q.dates[0]}/${q.dates[1]}`
            }
          })
          setValueInput(querys)
        } else {
          setValueInput(items)
        }
        setQuerySelected(historyData.searchHistory)
        setHistoriesSearchValue([historyData.searchHistory])
      }
    },
  })
  const motorUppercaseToLowerCase = (motor) => {
    let motorChoice = ''
    if (motor === null) {
      motorChoice = 'all'
    } else if (motor === 'PUB_MED') {
      motorChoice = 'pubmed'
    } else if (motor === 'EDS') {
      motorChoice = 'eds'
    } else if (motor === 'ESPACENET') {
      motorChoice = 'espacenet'
    } else {
      motorChoice = 'ebscohost'
    }
    return motorChoice
  }
  useEffect(() => window.scrollTo(0, 0), [liveSearch])

  const [deleteHistory] = useMutation(graphql.DELETE_SEARCH_HISTORY_BY_ID_USER)

  const [createHistorySearch] = useMutation(graphql.CREATE_SEARCH_HISTORY_BY_ID_USER, {
    onCompleted: (data) => {
      setHideUnselected(false)
      const alreadySearched = dataHistoriesSearch?.searchHistories?.nodes
        ?.find((e) => e.searchedQuery === data.createSearchHistory.searchHistory.searchedQuery)
      if (alreadySearched) {
        deleteHistory({
          variables: {
            id: alreadySearched.id,
          },
        }).catch(() => {
          deleteHistory({
            variables: {
              id: data.createSearchHistory.searchHistory.id,
            },
          })
        })
      }
      const motorChoice = motorUppercaseToLowerCase(
        data.createSearchHistory.searchHistory.searchEngine,
      )
      // if query advanced *******************************************************************
      setMotorAdvancedQuerySelected(data.createSearchHistory.searchHistory.searchEngine)
      if (data.createSearchHistory.searchHistory.searchedQuery[0]) {
        if (openSearchAdvanced) {
          fetchHistorySearchById({
            variables: { id: data.createSearchHistory.searchHistory.id } })
          setQueries(`?multisearch=${data.createSearchHistory.searchHistory.id}&type=${motorChoice !== 'all' ? motorChoice : 'pubmed'}`)
          window.history.replaceState(null, null, `/searchresult?multisearch=${data.createSearchHistory.searchHistory.id}&type=${motorChoice !== 'all' ? motorChoice : 'pubmed'}`)
          setOpenSearchAdvanced(false)
        }
      }
      refetch()
    },
  })

  // single search query ***********************************************************************
  const changeEspacenetQueries = () => {
    let timesearch = 'year=0'
    if (filterEspacenet === 'specificPeriod') {
      timesearch = `from=1900&to=${yearNow}`
    } else if (filterEspacenet.to || filterEspacenet.from) {
      timesearch = `from=${filterEspacenet.from || '1900'}&to=${filterEspacenet.to || yearNow}`
    } else if (filterEspacenet.old) {
      timesearch = `old=${filterEspacenet.old}`
    } else {
      timesearch = `year=${filterEspacenet}`
    }
    if (query.get('multisearch') && querySelected) {
      setQueries(`?multisearch=${query.get('multisearch')}&type=espacenet&${timesearch}`)
      window.history.replaceState(null, null, `/searchresult?multisearch=${querySelected.id}&type=espacenet&${timesearch}`)
    } else {
      setQueries(`/searchresult?value=${valueInput}&type=espacenet&${timesearch}`)
      window.history.replaceState(null, null, `/searchresult?value=${valueInput}&type=espacenet&${timesearch}`)
    }
  }

  const changeEdsQueries = () => {
    let timesearch = 'year=0'
    if (filterEds === 'specificPeriod') {
      timesearch = `from=1900&to=${yearNow}`
    } else if (filterEds.to || filterEds.from) {
      timesearch = `from=${filterEds.from || '1900'}&to=${filterEds.to || yearNow}`
    } else if (filterEds.old) {
      timesearch = `old=${filterEds.old}`
    } else {
      timesearch = `year=${filterEds}`
    }
    if (query.get('multisearch') && querySelected) {
      setQueries(`?multisearch=${query.get('multisearch')}&type=eds&${timesearch}`)
      window.history.replaceState(null, null, `/searchresult?multisearch=${querySelected.id}&type=eds&${timesearch}`)
    } else {
      setQueries(`?value=${valueInput}&type=eds&${timesearch}`)
      window.history.replaceState(null, null, `/searchresult?value=${valueInput}&type=eds&${timesearch}`)
    }
  }

  const changePubmedQueries = () => {
    let timesearch = 'year=0'
    if (filterPubmed === 'specificPeriod') {
      timesearch = `from=1900&to=${yearNow}`
    } else if (filterPubmed.to || filterPubmed.from) {
      timesearch = `from=${filterPubmed.from || '1900'}&to=${filterPubmed.to || yearNow}`
    } else if (filterPubmed.old) {
      timesearch = `old=${filterPubmed.old}`
    } else {
      timesearch = `year=${filterPubmed}`
    }

    if (query.get('multisearch') && !openSearchAdvanced && querySelected) {
      setQueries(`?multisearch=${query.get('multisearch')}&type=pubmed&${timesearch}&freefulltext=${freefulltext}&sort=${pubmedSort}`)
      window.history.replaceState(null, null, `/searchresult?multisearch=${query.get('multisearch')}&type=pubmed&${timesearch}&freefulltext=${freefulltext}&sort=${pubmedSort}`)
    } else {
      setQueries(`/searchresult?value=${valueInput}&type=pubmed&${timesearch}&freefulltext=${freefulltext}&sort=${pubmedSort}`)
      window.history.replaceState(null, null, `/searchresult?value=${valueInput}&type=pubmed&${timesearch}&freefulltext=${freefulltext}&sort=${pubmedSort}`)
    }
  }

  const fetchResultsPubmed = async (more = false) => {
    if (!more) {
      setLoadingPubmed(true)
    }
    let valueSearch = ''
    if (valueInput.length === 8 && !!parseInt(valueInput, 10)) {
      valueSearch = valueInput
    } else if (filterPubmed.old) {
      const date = new Date()
      const sinceDate = moment(date).subtract(filterPubmed.old, 'year').format('YYYY/MM/DD')
      valueSearch = `(${valueInput}) AND (${sinceDate}[Date - Publication] : "3000"[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    } else if (filterPubmed.from || filterPubmed.to || filterPubmed === 'specificPeriod') {
      valueSearch = `(${valueInput}) AND (${filterPubmed.from || '1900'}[Date - Publication] : ${filterPubmed.to || yearNow}[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    } else {
      valueSearch = `(${valueInput}) AND (${filterPubmed.toString()}[Date - Publication] : "3000"[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    }
    if (liveSearch === 'pubmed') {
      changePubmedQueries()
    }
    if (more) {
      await api.resultsPubmet(valueSearch, limit, pubmedOffset, pubmedSort)
        .then((data) => {
          setResultsPubmet({ items: resultsPubmet.items.concat(data.items),
            total: resultsPubmet.total })
          setPubmedOffset(pubmedOffset + limit)
        })
    } else {
      await api.resultsPubmet(valueSearch, limit, offset + 1, pubmedSort)
        .then((data) => {
          setResultsPubmet(data)
          setPubmedOffset(51)
        })
        .catch(() => {
          setResultsPubmet([])
          setLoadingPubmed(false)
        })
    }
    setLoadingPubmed(false)
  }

  /*const fetchResultsEds = async (more = false) => {
    if (!more) {
      setLoadingEds(true)
    }
    let limiter = ''
    if (filterEds.old) {
      const date = moment().format('YYYY-MM')
      const sinceDate = moment(date).subtract(filterEds.old, 'year').format('YYYY-MM')
      limiter = `DT1:${sinceDate}/${date}`
    } else if (filterEds.from || filterEds.to || filterEds === 'specificPeriod') {
      limiter = `DT1:${filterEds.from || 1900}-01/${filterEds.to || yearNow}-01`
    } else if (filterEds !== '0') {
      limiter = `DT1:${filterEds}-01/${yearNow}-01`
    }
    if (liveSearch === 'eds') {
      changeEdsQueries()
    }
    if (more) {
      await api.resultsEds(valueInput, limit, edsOffset, limiter)
        .then((data) => {
          setResultsEds({ items: resultsEds.items.concat(data.items),
            total: resultsEds.total })
          setEdsOffset(edsOffset + limit)
        })
        .catch(() => {
          setLoadingEds(false)
        })
    } else {
      await api.resultsEds(valueInput, limit, offset, limiter)
        .then((data) => {
          setResultsEds(data)
          setEdsOffset(50)
        })
        .catch(() => {
          setResultsEds({ items: [] })
          setLoadingEds(false)
        })
    }
    setLoadingEds(false)
  }*/

  const fetchResultsEspacenet = async (more = false) => {
    setErrorSpacenet(false)
    if (!more) {
      setLoadingSpacenet(true)
    }
    let valueSearch = ''
    if (filterEspacenet.old) {
      const sinceDate = `${yearNow - filterEspacenet.old}0101`
      valueSearch = `${valueInput} and pd > ${sinceDate}`
    } else if (filterEspacenet === '0') {
      valueSearch = valueInput
    } else if (filterEspacenet.from || filterEspacenet.to || filterEspacenet === 'specificPeriod') {
      const fromDate = `${filterEspacenet.from || '1900'}0101`
      const toDate = `${filterEspacenet.to || yearNow}1231`
      valueSearch = `${valueInput} and pd within "${fromDate} ${toDate}"`
    } else {
      const sinceDate = `${filterEspacenet}0101`
      valueSearch = `${valueInput} and pd > ${sinceDate}`
    }
    if (liveSearch === 'espacenet') {
      changeEspacenetQueries()
    }
    if (more) {
      await api.resultsSpacenet(valueSearch, limit, espacenetOffset)
        .then((data) => {
          setResultsSpacenet({ items: resultsSpacenet.items.concat(data.items),
            total: resultsSpacenet.total })
          setEspacenetOffset(espacenetOffset + limit)
        })
    } else {
      await api.resultsSpacenet(valueSearch, limit, offset)
        .then((data) => {
          setResultsSpacenet(data)
          setEspacenetOffset(50)
        })
        .catch(() => {
          setResultsSpacenet([])
          setLoadingSpacenet(false)
          setErrorSpacenet(true)
        })
    }
    setLoadingSpacenet(false)
  }

  const fetchResultsEbscohost = async (more = false) => {
    if (more) {
      await api.resultsEbscohost(valueInput, limit, ebscohostOffset)
        .then((data) => {
          setResultsEbscohost({ items: resultsEbscohost.items.concat(data.items),
            total: resultsEbscohost.total })
          setEbscohostOffset(ebscohostOffset + limit)
        })
    } else {
      setLoadingEbscohost(true)
      await api.resultsEbscohost(valueInput, limit, offset)
        .then((data) => {
          setResultsEbscohost(data)
          setEbscohostOffset(50)
          setLoadingEbscohost(false)
        })
        .catch((e) => {
          setResultsEbscohost([])
          setLoadingEbscohost(false)
        })
    }
  }
  // *********************************************************************************************

  useEffect(() => {
    if (querySelected || !query.get('multisearch')) {
      if (liveSearch === 'pubmed') {
        changePubmedQueries()
      } else if (liveSearch === 'eds') {
        changeEdsQueries()
      } else if (liveSearch === 'espacenet') {
        changeEspacenetQueries()
      } else {
        setQueries(`?value=${valueInput}&type=ebscohost`)
        window.history.replaceState(null, null, `/searchresult?value=${valueInput}&type=ebscohost`)
      }
    }
  }, [valueChange, liveSearch, pubmedSort])

  useEffect(() => {
    if (!query.get('multisearch') && !query.get('history')) {
      fetchResultsPubmed()
      fetchResultsEspacenet()
      fetchResultsEbscohost()
      //fetchResultsEds()
    } else {
      fetchHistorySearchById({
        variables: {
          id: query.get('multisearch') || query.get('history'),
        },
      })
    }
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      createHistorySearch({
        variables: {
          idUser: id,
          searchedQuery: valueInput.trim(),
        },
      })
      setValueInput(valueInput.trim())
      setValueChange(valueInput.trim())
    }
  }

  useEffect(() => {
    if (valueChange) {
      fetchResultsPubmed()
      fetchResultsEspacenet()
      fetchResultsEbscohost()
      //fetchResultsEds()
      if (!openSearchAdvanced) {
        window.history.replaceState(null, null, `/searchresult?value=${valueInput.trim()}&type=${liveSearch}`)
      }
    }
  }, [valueChange])

  // advanced functions ************
  const changeValueQuerys = (items, motor) => {
    let motorChoice = ''
    if (motor === 'all') {
      motorChoice = null
    } else if (motor === 'pubmed') {
      motorChoice = 'PUB_MED'
    } else if (motor === 'espacenet') {
      motorChoice = 'ESPACENET'
    } else if (motor === 'ebscohost') {
      motorChoice = 'EBSCOHOST'
    } else {
      motorChoice = 'EDS'
    }
    setMotorAdvancedQuerySelected(motorChoice)
    setJsonQueryAdvanced([...items])
    const params = []
    items.forEach((q) => {
      if (q.query.trim() !== '' && q.field !== 'date' && q.keyField !== 'date') {
        if (q.operator) {
          params.push({
            operator: q.operator,
            query: q.query.trim(),
            field: q.field,
            keyField: q.keyField,
            implicitFields: q.implicitFields,
          })
        } else {
          params.push({
            query: q.query.trim(),
            field: q.field,
            keyField: q.keyField,
            implicitFields: q.implicitFields,
          })
        }
      } else if (!q.dates.includes(null)) {
        params.push({
          keyField: q.keyField,
          dates: [q.dates?.[0] ? q.dates[0] : moment().format('YYYY-MM-DD'), q.dates?.[1] ? q.dates[1] : moment().format('YYYY-MM-DD')],
          operator: q.operator,
        })
      }
    })
    setJsonQueryAdvanced(params)
  }

  const fetchResultsPubmedMultiQuerys = async (more = false) => {
    if (!more) {
      setLoadingPubmed(true)
    }
    let valueSearch = ''
    let dateQuery = ''
    if (querySelected?.searchEngine !== null) {
      querySelected.searchedQuery.forEach((q) => {
        if (q.field !== 'date' && q.keyField !== 'date') {
          if (q.operator) {
            if (q.implicitFields.length > 0) {
              valueSearch += `${q.operator} (${q.query} ${q.field ? `[${q.field}]` : ''}) `
              for (const newField of q.implicitFields) {
                valueSearch += ` OR (${q.query} ${newField ? `[${newField}]` : ''}) `
              }
            } else {
              valueSearch += `${q.operator} (${q.query} ${q.field ? `[${q.field}]` : ''}) `
            }
          } else if (q.implicitFields.length > 0) {
            valueSearch += `(${q.query}${q.field ? `[${q.field}]` : ''}) `
            for (const newField of q.implicitFields) {
              valueSearch += ` OR (${q.query} ${newField ? `[${newField}]` : ''}) `
            }
          } else {
            valueSearch += `(${q.query}${q.field ? `[${q.field}]` : ''}) `
          }
        } else {
          dateQuery = q.dates
        }
      })
    } else {
      querySelected.searchedQuery.forEach((q) => {
        const keyQuery = q.keyField
        const fieldValue = valuesKeyAllMotors[keyQuery].pubmed
        if (keyQuery !== 'date') {
          if (q.operator) {
            valueSearch += `${q.operator} (${q.query}${fieldValue && `[${fieldValue}]`}) `
          } else {
            valueSearch += `(${q.query}${fieldValue && `[${fieldValue}]`}) `
          }
        } else {
          dateQuery = q.dates
        }
      })
    }
    if (filterPubmed.old) {
      const date = new Date()
      const sinceDate = moment(date).subtract(filterPubmed.old, 'year').format('YYYY/MM/DD')
      valueSearch += ` AND (${sinceDate}[Date - Publication] : "3000"[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    } else if (filterPubmed.from || filterPubmed.to || filterPubmed === 'specificPeriod') {
      valueSearch += ` AND (${filterPubmed.from || '1900'}[Date - Publication] : ${filterPubmed.to || yearNow}[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    } else if (filterPubmed !== '0') {
      valueSearch += ` AND (${filterPubmed.toString()}[Date - Publication] : "3000"[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    } else if (dateQuery) {
      valueSearch += ` AND (${moment(dateQuery[0]).format('YYYY/MM/DD')}[Date - Publication] : ${moment(dateQuery[1]).format('YYYY/MM/DD')}[Date - Publication]) ${freefulltext ? `AND ${freefulltext}` : ''}`
    }
    if (liveSearch === 'pubmed') {
      changePubmedQueries()
    }

    if (more) {
      await api.resultsPubmet(valueSearch, limit, pubmedOffset, pubmedSort)
        .then((data) => {
          setResultsPubmet({ items: resultsPubmet.items.concat(data.items),
            total: resultsPubmet.total })
          setPubmedOffset(pubmedOffset + limit)
        })
    } else {
      await api.resultsPubmet(valueSearch, limit, offset, pubmedSort)
        .then((data) => {
          setResultsPubmet(data)
          setPubmedOffset(50)
        })
        .catch(() => {
          setResultsPubmet([])
          setLoadingPubmed(false)
        })
    }
    setLoadingPubmed(false)
  }

  /*const fetchResultsEdsMultiQuerys = async (more = false) => {
    if (!more) {
      setLoadingEds(true)
    }
    let valueSearch = ''
    let dateQuery = ''
    if (querySelected?.searchEngine !== null) {
      querySelected.searchedQuery.forEach((q) => {
        if (q.field !== 'date' && q.keyField !== 'date') {
          if (q.operator) {
            valueSearch += ` ${q.operator} ${q.field ? `${q.field} ` : ''}${q.query}`
          } else {
            valueSearch += `${q.field ? `${q.field} ` : ''}${q.query}`
          }
        } else {
          dateQuery = q.dates
        }
      })
    } else {
      querySelected.searchedQuery.forEach((q) => {
        const keyQuery = q.keyField
        const fieldValue = valuesKeyAllMotors[keyQuery].eds
        if (keyQuery !== 'date') {
          if (q.operator) {
            valueSearch += ` ${q.operator} ${fieldValue ? `${fieldValue} ` : ''}${q.query}`
          } else {
            valueSearch += `${fieldValue ? `${fieldValue} ` : ''}${q.query}`
          }
        } else {
          dateQuery = q.dates
        }
      })
    }
    let limiter = ''
    if (filterEds.old) {
      const date = moment().format('YYYY-MM')
      const sinceDate = moment(date).subtract(filterEds.old, 'year').format('YYYY-MM')
      limiter = `DT1:${sinceDate}/${date}`
    } else if (filterEds.from || filterEds.to || filterEds === 'specificPeriod') {
      limiter = `DT1:${filterEds.from || 1900}-01/${filterEds.to || yearNow}-01`
    } else if (filterEds !== '0') {
      limiter = `DT1:${filterEds}-01/${yearNow}-01`
    } else if (dateQuery) {
      limiter = `DT1:${moment(dateQuery[0])
        .format('YYYY-MM')}/${moment(dateQuery[1]).format('YYYY-MM')}`
    }
    if (liveSearch === 'eds') {
      changeEdsQueries()
    }

    if (more) {
      await api.resultsEds(valueSearch, limit, edsOffset, limiter)
        .then((data) => {
          setResultsEds({ items: resultsEds.items.concat(data.items),
            total: resultsEds.total })
          setEdsOffset(edsOffset + limit)
        })
    } else {
      await api.resultsEds(valueSearch, limit, offset, limiter)
        .then((data) => {
          setResultsEds(data)
          setEdsOffset(50)
        })
        .catch(() => {
          setResultsEds({ items: [] })
          setLoadingEds(false)
        })
    }
    setLoadingEds(false)
  }
*/
  useEffect(() => {
    if (!query.get('multisearch')) {
      fetchResultsPubmed()
    } else if (querySelected) {
      fetchResultsPubmedMultiQuerys()
    }
  }, [pubmedSort])

  const fetchResultsEspacenetMultiQuerys = async (more = false) => {
    setErrorSpacenet(false)
    if (!more) {
      setLoadingSpacenet(true)
    }
    let valueSearch = ''
    let dateQuery = ''
    if (querySelected?.searchEngine !== null) {
      querySelected.searchedQuery.forEach((q) => {
        if (q.field !== 'date' && q.keyField !== 'date') {
          if (q.operator) {
            if (q.implicitFields.length > 0) {
              valueSearch += ` ${q.operator} ${q.field || 'txt'}=${q.query}`
              for (const newField of q.implicitFields) {
                valueSearch += ` OR ${newField}=${q.query}`
              }
            } else {
              valueSearch += ` ${q.operator} ${q.field || 'txt'}=${q.query}`
            }
          } else if (q.implicitFields.length > 0) {
            valueSearch += `${q.field}=${q.query}`
            for (const newField of q.implicitFields) {
              valueSearch += ` OR ${newField}=${q.query}`
            }
          } else {
            valueSearch += `${q.field || 'txt'}=${q.query}`
          }
        } else {
          dateQuery = q.dates
        }
      })
    } else {
      querySelected.searchedQuery.forEach((q) => {
        const keyQuery = q.keyField
        const fieldValue = valuesKeyAllMotors[keyQuery].espacenet
        if (keyQuery !== 'date') {
          if (fieldValue) {
            if (q.operator) {
              valueSearch += ` ${q.operator} ${fieldValue}=${q.query}`
            } else {
              valueSearch += `${fieldValue}=${q.query}`
            }
          }
        } else {
          dateQuery = q.dates
        }
      })
    }

    if (filterEspacenet.old) {
      const sinceDate = `${yearNow - filterEspacenet.old}0101`
      valueSearch += ` and pd > ${sinceDate}`
    } else if (filterEspacenet.from || filterEspacenet.to || filterEspacenet === 'specificPeriod') {
      const fromDate = `${filterEspacenet.from || '1900'}0101`
      const toDate = `${filterEspacenet.to || yearNow}1231`
      valueSearch += ` and pd within "${fromDate} ${toDate}"`
    } else if (filterEspacenet !== '0') {
      valueSearch += ' and pd > 00000101'
    } else if (dateQuery) {
      valueSearch += ` and pd within "${dateQuery[0]} ${dateQuery[1]}"`
    }
    if (liveSearch === 'espacenet') {
      changeEspacenetQueries()
    }

    if (more) {
      await api.resultsSpacenet(valueSearch, limit, espacenetOffset)
        .then((data) => {
          setResultsSpacenet({ items: resultsSpacenet.items.concat(data.items),
            total: resultsSpacenet.total })
          setEspacenetOffset(espacenetOffset + limit)
        })
    } else {
      await api.resultsSpacenet(valueSearch, limit, offset)
        .then((data) => {
          setResultsSpacenet(data)
          setEspacenetOffset(50)
        })
        .catch(() => {
          setResultsSpacenet([])
          setLoadingSpacenet(false)
          setErrorSpacenet(true)
        })
    }
    setLoadingSpacenet(false)
  }

  const fetchResultsEbscohostMultiQuerys = async (more = false) => {
    let valueSearch = ''
    if (querySelected?.searchEngine !== null) {
      querySelected.searchedQuery.forEach((q) => {
        if (q.field !== 'date' && q.keyField !== 'date') {
          if (q.operator) {
            valueSearch += ` ${q.operator} ${q.field} (${q.query})`
          } else {
            valueSearch += `${q.field}(${q.query})`
          }
        }
      })
    } else {
      querySelected.searchedQuery.forEach((q) => {
        const keyQuery = q.keyField
        const fieldValue = valuesKeyAllMotors[keyQuery].ebscohost

        if (keyQuery !== 'date') {
          if (q.operator) {
            valueSearch += ` ${q.operator} ${fieldValue} (${q.query})`
          } else {
            valueSearch += `${fieldValue} (${q.query})`
          }
        }
      })
    }

    if (more) {
      await api.resultsEbscohost(valueSearch, limit, ebscohostOffset)
        .then((data) => {
          setResultsEbscohost({ items: resultsEbscohost.items.concat(data.items),
            total: resultsEbscohost.total })
          setEbscohostOffset(ebscohostOffset + limit)
        })
    } else {
      setLoadingEbscohost(true)
      await api.resultsEbscohost(valueSearch, limit, offset)
        .then((data) => {
          setResultsEbscohost(data)
          setEbscohostOffset(0)
          setLoadingEbscohost(false)
        })
        .catch((e) => {
          setLoadingEbscohost(false)
          setResultsEbscohost([])
        })
    }
  }

  useEffect(() => {
    if (querySelected && typeof querySelected.searchedQuery === 'object') {
      if (querySelected.searchEngine === 'PUB_MED') {
        fetchResultsPubmedMultiQuerys()
      } else if (querySelected.searchEngine === 'EBSCOHOST') {
        fetchResultsEbscohostMultiQuerys()
      } else if (querySelected.searchEngine === 'ESPACENET') {
        fetchResultsEspacenetMultiQuerys()
      } else if (querySelected.searchEngine === 'EDS') {
        //fetchResultsEdsMultiQuerys()
      } else {
        fetchResultsPubmedMultiQuerys()
        fetchResultsEbscohostMultiQuerys()
        fetchResultsEspacenetMultiQuerys()
        //fetchResultsEdsMultiQuerys()
      }
    }
  }, [querySelected])

  const hideDocs = () => {
    setHideUnselected((hide) => !hide)
  }

  const searchHistory = (searchValue) => {
    setValueInput(searchValue.searchedQuery)
    setOpenHistoric(false)
    refetch()
    if (typeof searchValue.searchedQuery === 'object') {
      fetchHistorySearchById({
        variables: {
          id: searchValue.id,
        },
      })
      setQueries(`?multisearch=${searchValue.id}&type=${!searchValue.searchEngine ? liveSearch : motorUppercaseToLowerCase(searchValue.searchEngine)}`)
      window.history.replaceState(null, null, `/searchresult?multisearch=${searchValue.id}&type=${!searchValue.searchEngine ? liveSearch : motorUppercaseToLowerCase(searchValue.searchEngine)}`)
      setValueChange('')
    } else {
      setValueChange(searchValue.searchedQuery)
    }
  }

  return (
    <main className="resultsPage">
      <section className="resultsPage__sectionSearch">
        <div className="resultsPage__container resultsPage__container--openAdvanced">
          {openHistoric && (
            <History
              onSearch={(searchValue) => searchHistory(searchValue)}
              close={() => setOpenHistoric(false)}
              history={dataHistoriesSearch}
            />
          )}
          <div className="resultsPage__inputSearch">
            <button
              type="button"
              className="resultsPage__inputSearch--btnLeft"
              onClick={() => setOpenHistoric(true)}
            >
              <img className="search__input--img" src="/img/picto-historique-violet.svg" alt="icon search" />
            </button>
            <input
              disabled={openSearchAdvanced}
              value={valueInput}
              type="text"
              name="search"
              id="search"
              className="resultsPage__inputSearch--input"
              placeholder={t('placeholder')}
              onChange={(e) => {
                setJsonQueryAdvanced([])
                setQuerySelected(null)
                setValueInput(e.target.value)
              }}
              onKeyPress={(event) => handleKeyPress(event)}
            />
            {openSearchAdvanced ? (
              <button
                className="resultsPage__inputSearch--btnRightTwo"
                type="button"
                onClick={() => setOpenSearchAdvanced(false)}
                alt="close search advanced"
              >
                <img src="/img/picto-close.svg" alt="icon search" style={{ width: '16px' }} />
              </button>
            ) : (
              <>
                <button
                  className="resultsPage__inputSearch--btnRightTwo"
                  type="button"
                  onClick={() => setOpenSearchAdvanced(true)}
                >
                  <img src="/img/dots.svg" alt="icon search" />
                </button>
              </>
            )}
          </div>
          <p className="resultsPage__inputSearch--label">{t('labelSearchBar')}</p>
          {openSearchAdvanced && (
            <div className="resultsPage__advancedOpen">
              <AdvancedSearchForm
                close={() => {
                  setOpenSearchAdvanced(false)
                  setJsonQueryAdvanced([])
                }}
                changeValueQuerys={changeValueQuerys}
                querySelected={querySelected}
                singleValueInput={query.get('multisearch') ? null : valueInput}
              />
              <button
                type="button"
                disabled={!jsonQueryAdvanced.filter((e) => e.query?.trim()).length}
                className="resultsPage__searchBtn"
                onClick={() => {
                  if (motorFilterAdvancedQuerySelected) {
                    createHistorySearch({
                      variables: {
                        idUser: id,
                        searchedQuery: jsonQueryAdvanced,
                        searchEngine: motorFilterAdvancedQuerySelected,
                      },
                    })
                  } else {
                    createHistorySearch({
                      variables: {
                        idUser: id,
                        searchedQuery: jsonQueryAdvanced,
                      },
                    })
                  }
                }}
              >
                {t('search')}

              </button>
            </div>
          )}
        </div>
        <div className="flex">
          <SortButtons
            sorted={liveSearch}
            changeSorted={setLiveSearch}
            querySelected={querySelected}
          />
        </div>
      </section>
      <nav className="resultsPage__nav">
        {liveSearch !== 'ebscohost' && (
          <FilterResults
            filter={filters()}
            setFilter={(filter) => {
              if (liveSearch === 'pubmed') {
                setFilterPubmed(filter)
              } else if (liveSearch === 'espacenet') {
                setFilterEspacenet(filter)
              } else if (liveSearch === 'eds') {
                setFilterEds(filter)
              }
            }}
            query={query}
            liveSearch={liveSearch}
            fetchResults={() => {
              if (liveSearch === 'pubmed') {
                fetchResultsPubmed()
              } else if (liveSearch === 'espacenet') {
                fetchResultsEspacenet()
              } else if (liveSearch === 'eds') {
                //fetchResultsEds()
              }
            }}
            fetchResultsMultiQuerys={() => {
              if (liveSearch === 'pubmed') {
                fetchResultsPubmedMultiQuerys()
              } else if (liveSearch === 'espacenet') {
                fetchResultsEspacenetMultiQuerys()
              } else if (liveSearch === 'eds') {
                //fetchResultsEdsMultiQuerys()
              }
            }}
            sort={pubmedSort}
            setSort={(sort) => setPubmedSort(sort)}
            freefulltext={freefulltext}
            setFreefulltext={(text) => setFreefulltext(text)}
          />
        )}

      </nav>
      <section className="resultsPage__articles">
        {((liveSearch === 'pubmed' && loadingPubmed && !hideUnselected) || (liveSearch === 'espacenet' && loadingSpacenet) || (liveSearch === 'ebscohost' && loadingEbscohost) || (liveSearch === 'eds' && loadingEds)) && (
          <>
            <div className="resultsPage__loading" />
            <p className="resultsPage__loading--text">{t('searching')}</p>
          </>
        )}

        <ScrollResults
          results={results()}
          isLoading={isLoading()}
          fetchNext={() => {
            if (liveSearch === 'pubmed') {
              if (query.get('multisearch')) {
                fetchResultsPubmedMultiQuerys(true)
              } else {
                fetchResultsPubmed(true)
              }
            } else if (liveSearch === 'espacenet') {
              if (query.get('multisearch')) {
                fetchResultsEspacenetMultiQuerys(true)
              } else {
                fetchResultsEspacenet(true)
              }
            } else if (liveSearch === 'eds') {
              if (query.get('multisearch')) {
                //fetchResultsEdsMultiQuerys(true)
              } else {
                //fetchResultsEds(true)
              }
            } else if (query.get('multisearch')) {
              fetchResultsEbscohostMultiQuerys(true)
            } else {
              fetchResultsEbscohost(true)
            }
          }}
          liveSearch={liveSearch}
          resultsSelected={resultsSelected}
          setResultsSelected={setResultsSelected}
          setHistoriesSearchValue={setHistoriesSearchValue}
          offset={(offset)}
          errorSpacenet={errorSpacenet}
          queries={queries}
          hideDocs={() => hideDocs()}
          hideUnselected={liveSearch === 'pubmed' ? hideUnselected : false}
          querySelected={querySelected?.searchedQuery}
        />
      </section>
      <ChatDoc
        resultsSelected={resultsSelected}
        liveSearch={liveSearch}
        setResultsSelected={setResultsSelected}
      />
    </main>
  )
}

export default SearchResultPage
