import { useMutation, useQuery } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AdvancedSearchForm, History } from '../../components'
import graphql from '../../graphql'
import '../../styles/app.scss'

const SearchPage = () => {
  const { t } = useTranslation('searchPage')
  const [searchValue, setSearchValue] = useState()
  const [openHistory, setOpenHistory] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()
  const [openSearchAdvanced, setOpenSearchAdvanced] = useState(false)
  // if query advanced **********************************
  const [jsonQueryAdvancedCompleted, setJsonQueryAdvancedCompleted] = useState([])
  const [motorSelected, setMotorSelected] = useState()

  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const { id } = decoded

  const { data: dataHistoriesSearch } = useQuery(
    graphql.SEARCH_HISTORIES_BY_ID_USER,
    {
      fetchPolicy: 'network-only',
      variables: {
        idUser: id,
      },
    },
  )

  const [deleteHistory] = useMutation(graphql.DELETE_SEARCH_HISTORY_BY_ID_USER)

  const [createHistorySearch] = useMutation(graphql.CREATE_SEARCH_HISTORY_BY_ID_USER, {
    variables: {
      idUser: id,
      searchEngine: motorSelected || null,
      searchedQuery: openSearchAdvanced ? jsonQueryAdvancedCompleted : searchValue,
    },
    onCompleted: (data) => {
      const alreadySearched = dataHistoriesSearch?.searchHistories?.nodes
        ?.find((e) => e.searchedQuery === data.createSearchHistory.searchHistory.searchedQuery)
      if (alreadySearched) {
        deleteHistory({
          variables: {
            id: alreadySearched.id,
          },
        })
      }
      let motor = 'all'
      if (motorSelected === 'PUB_MED') {
        motor = 'pubmed'
      } else if (motorSelected) {
        motor = motorSelected.toLowerCase()
      }

      if (openSearchAdvanced) {
        history.push(
          `/searchresult?multisearch=${data.createSearchHistory.searchHistory.id}&type=${motor !== 'all' ? motor : 'pubmed'}`,
        )
      } else if (searchValue && !openSearchAdvanced) {
        history.push(`/searchresult?value=${searchValue?.trim()}`)
      } else {
        history.push('#')
      }
    },
  })

  const sendSearch = () => {
    if (searchValue
      || (openSearchAdvanced && jsonQueryAdvancedCompleted.filter((e) => e.query).length)) {
      createHistorySearch()
    } else {
      setError(true)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendSearch()
    }
  }

  const changeValueQuerys = (items, motor) => {
    if (motor !== 'all') {
      if (motor === 'pubmed') { setMotorSelected('PUB_MED') }
      if (motor === 'espacenet') { setMotorSelected('ESPACENET') }
      if (motor === 'ebscohost') { setMotorSelected('EBSCOHOST') }
      if (motor === 'eds') { setMotorSelected('EDS') }
    } else {
      setMotorSelected(null)
    }
    const params = []
    items.forEach((q) => {
      if (q.query.trim() !== '' && q.field !== 'date' && q.keyField !== 'date') {
        if (q.operator) {
          params.push({
            operator: q.operator,
            query: q.query.trim(),
            field: q.field,
            keyField: q.keyField,
            implicitFields: q.implicitFields,
          })
        } else {
          params.push({
            query: q.query.trim(),
            field: q.field,
            keyField: q.keyField,
            implicitFields: q.implicitFields,
          })
        }
      } else if (!q.dates.includes(null)) {
        params.push({
          keyField: q.keyField,
          dates: [q.dates[0] ? q.dates[0] : moment().format('YYYY-MM-DD'), q.dates[1] ? q.dates[1] : moment().format('YYYY-MM-DD')],
          operator: q.operator,
        })
      }
    })
    setJsonQueryAdvancedCompleted(params)
  }

  const motorUppercaseToLowerCase = (motor) => {
    let motorChoice = ''
    if (motor === null) {
      motorChoice = 'all'
    } else if (motor === 'PUB_MED') {
      motorChoice = 'pubmed'
    } else if (motor === 'EDS') {
      motorChoice = 'eds'
    } else if (motor === 'ESPACENET') {
      motorChoice = 'espacenet'
    } else {
      motorChoice = 'ebscohost'
    }
    return motorChoice
  }

  const searchHistory = (search) => {
    setOpenHistory(false)
    if (typeof search.searchedQuery === 'object') {
      history.push(`/searchresult?multisearch=${search.id}&type=${!search.searchEngine ? 'pubmed' : motorUppercaseToLowerCase(search.searchEngine)}`)
    } else {
      history.push(`/searchresult?value=${search.searchedQuery}`)
    }
  }

  return (
    <div className="search">
      <nav className="search__nav">
        <a href="/profile" className="search__linkLibrary">
          <p>{t('profile')}</p>
        </a>
      </nav>
      <div className="search__container">
        <img className="search__logo" src="img/icon-metari.svg" alt="logo" />
        <div className={openSearchAdvanced ? 'search__searchContainer search__searchContainer--open' : 'search__searchContainer search__searchContainer--close'}>
          <div className="search__containerInput">
            <button className="search__history" type="button" onClick={() => setOpenHistory(true)}>
              <img className="search__input--img" src="/img/picto-historique-violet.svg" alt="icon search" />
            </button>
            <input
              className="search__input search__input--noFocus"
              placeholder={t('placeholder')}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(event) => handleKeyPress(event)}
              disabled={openSearchAdvanced}
            />
            {openHistory && (
              <History
                close={() => setOpenHistory(false)}
                onSearch={(searchQuery) => searchHistory(searchQuery)}
                history={dataHistoriesSearch}
              />
            )}
            {openSearchAdvanced ? (
              <button
                className="search__spreadBtn"
                type="button"
                onClick={() => setOpenSearchAdvanced(false)}
                alt="close search advanced"
              >
                <img src="/img/picto-close.svg" alt="icon search" style={{ width: '16px' }} />
              </button>
            ) : (
              <button
                className="search__spreadBtn"
                type="button"
                onClick={() => setOpenSearchAdvanced(true)}
                alt="open search advanced"
              >
                <img src="/img/dots.svg" alt="icon search" style={{ marginTop: '-5px' }} />
              </button>
            )}
          </div>
          {openSearchAdvanced && (
            <AdvancedSearchForm
              close={() => {
                setOpenSearchAdvanced(false)
                setJsonQueryAdvancedCompleted([])
              }}
              changeValueQuerys={changeValueQuerys}
              singleValueInput={searchValue}
            />
          )}
        </div>
        <button
          className={`search__btn ${!searchValue || !jsonQueryAdvancedCompleted ? 'disable' : ''}`}
          type="button"
          onClick={sendSearch}
        >
          {t('search')}
        </button>
        {error && (
          <p className="search__error">{t('error')}</p>
        )}
      </div>
      <footer className="search__footer">
        <a alt={t('cgv')} className="search__cgv">{t('cgv')}</a>
      </footer>
    </div>
  )
}

export default SearchPage
