/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { formatSearchQuery } from '../../lib/utils'
import FollowingGroups from '../FollowingGroups'
import gql from '../../graphql'

const SearchChatItem = ({
  searchValue,
  isSaveable,
  deleteSearchHistory,
  share,
  save,
  displayShare,
  group,
}) => {
  const [isDeleted, setIsDeleted] = useState(false)

  const {
    data: followingGroups,
    refetch: refetchFollowing } = useQuery(gql.getCommunitiesFollowingQuery(), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: searchValue[0].id,
    },
  })

  useEffect(() => {
    refetchFollowing()
  }, [group])

  const formatEngine = (engine) => {
    switch (engine) {
      case 'PUB_MED':
        return 'Pubmed'
      case 'EDS':
        return 'EDS'
      case 'ESPACENET':
        return 'Espacenet'
      case 'EBSCOHOST':
        return 'Ebscohost'
      default:
        break
    }
  }

  const { t } = useTranslation('tchat')
  return (
    <div className={`chat-list-item ${isDeleted ? 'bounceOut' : ''}`}>
      <div className="flex">
        <div className="flex left">
          <div>
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={() => {
                setIsDeleted(true)
                setTimeout(() => deleteSearchHistory(), 900)
              }}
            />
          </div>
          <div className="search-infos">
            <div className="head-txt">
              <p className="type">
                {t('history.type')}
              </p>
              {(followingGroups?.searchHistories?.nodes[0]
                ?.userGroupsSearchHistoriesByIdSearchHistory?.nodes?.length > 1 ||
              (followingGroups?.searchHistories?.nodes[0]
                ?.userGroupsSearchHistoriesByIdSearchHistory?.nodes?.length === 1 &&
                followingGroups?.searchHistories?.nodes[0]
                  ?.userGroupsSearchHistoriesByIdSearchHistory?.nodes[0]
                  ?.userGroupByIdUserGroup?.id !== group))
                && (
                  <FollowingGroups
                    t={t}
                    groups={followingGroups?.searchHistories?.nodes[0]
                      ?.userGroupsSearchHistoriesByIdSearchHistory?.nodes}
                  />
                )}
            </div>
            <p className="title">{formatSearchQuery(searchValue[0].searchedQuery)}</p>
            <p className="subTitle">{searchValue[0].searchEngine ? formatEngine(searchValue[0].searchEngine) : t('history.src')}</p>
          </div>
        </div>
        <div className="flex icons">
          <button disabled={!displayShare} className="icon" type="button" onClick={(() => share())}>
            <img src="/img/picto_share.svg" alt={t('share')} />
          </button>
          <button disabled={!isSaveable} className="icon" type="button" onClick={() => save()}>
            {isSaveable ? (
              <img src="/img/picto_save.svg" alt={t('save')} />
            ) : (
              <img src="/img/picto_save_disabled.svg" alt={t('save')} />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

SearchChatItem.propTypes = {
  searchValue: PropTypes.shape(PropTypes.shape()).isRequired,
  isSaveable: PropTypes.bool.isRequired,
  share: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  deleteSearchHistory: PropTypes.func.isRequired,
  displayShare: PropTypes.bool.isRequired,
  group: PropTypes.string.isRequired,
}

export default SearchChatItem
