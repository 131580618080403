import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Message = ({ message }) => (
  <div className="message">
    <p className="message__date">{moment(message.createdAt).format('DD/MM/YYYY')}</p>
    <div key={message.id}>
      <p>
        <span className="message__author">
          {`${message.userByIdUser.firstname[0]}. ${message.userByIdUser.lastname.toLowerCase()}`}
        </span>
        {`: ${message.content}`}
      </p>
    </div>
  </div>
)

Message.propTypes = {
  message: PropTypes.shape({
    userByIdUser: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }).isRequired,
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
}

export default Message
