import { gql } from '@apollo/client'

const fetchGroups = () => gql`
    query GetUserGroupsByUser($idUser: UUID!) {
        members(condition: {idUser: $idUser, membershipStatus: ACCEPTED}, orderBy: ID_DESC) {
            nodes {
                id
                membershipStatus
                userGroupByIdUserGroup {
                    name
                    id
                    description
                    visibility
                    idAdmin
                }
                userByIdUser {
                  idMainUserGroup
                }
            }
        }
    } 
  `

const getGroupsByName = () => gql`
  query getGroupsByName($name: String = "") {
    userGroups(filter: {name: {includesInsensitive: $name}}) {
      nodes {
        id
        name
        description
        visibility
        userByIdAdmin {
          id
          firstname
          lastname
        }
        membersByIdUserGroup {
           nodes {
            id
            idUser
            membershipStatus
          }
         }
      }
    }
  }
`

const fetchMessagesByIdUserGroup = () => gql`
    query fetchMessagesByIdUserGroup ($idUserGroup: UUID!, $idDocument: UUID!, $pIdUser: UUID!) {
        messages(condition: {idUserGroup: $idUserGroup, idDocument: $idDocument }, orderBy: CREATED_AT_ASC) {
            nodes {
                id
                content
                idUser
                createdAt
                userByIdUser {
                    firstname
                    lastname
                    id
                    email
                }
                isRead(pIdUser: $pIdUser)
            }
            totalCount
        }
    }
`

const fetchRatingsByIdUserGroup = () => gql`
    query fetchRatingsByIdUserGroup ($idUserGroup: UUID!, $idDocument: UUID!) {
        ratings(condition: {idUserGroup: $idUserGroup, idDocument: $idDocument }) {
            nodes {
                id
                value
                idUser
            }
        }
    }
`

const createMessage = () => gql`
    mutation MyMutation ($idUserGroup: UUID!, $idDocument: UUID!, $content: String!, $idUser: UUID) {
        createMessage(input: {message: {idUserGroup: $idUserGroup, idDocument: $idDocument, idUser: $idUser content: $content}}) {
            clientMutationId
            documentByIdDocument {
              id
            }
        }
    }
`

const upsertDocument = () => gql`
    mutation MyMutation ($pAuthor: String, $pDocumentType: TDocumentType,
            $pEngine: TEngine, $pExternalId: String, $pPublicationDate: Date, $pTitle: String){
        upsertDocument(
            input: {pAuthor: $pAuthor, pDocumentType: $pDocumentType, pEngine: $pEngine,
                pExternalId: $pExternalId, pPublicationDate: $pPublicationDate, pTitle: $pTitle}
        ) {
            uuid
        }
    }
`

const fetchMembers = () => gql`
    query fetchMembers($idUserGroup: UUID!) {
        members(condition: {idUserGroup: $idUserGroup, membershipStatus: ACCEPTED}) {
            edges {
                node {
                    id
                    membershipStatus
                    userByIdUser {
                        id
                        email
                        firstname
                        lastname
                        idMainUserGroup
                    }
                }
            }
        }
    }
`

const FETCH_MEMBERS_WAITING = gql`
    query fetchMembersWaiting($idUserGroup: UUID!) {
      members(condition: {idUserGroup: $idUserGroup, membershipStatus: WAITING}) {
        nodes {
          membershipStatus
          idUser
        }
      }
    }
`

const fetchWaitingMembers = () => gql`
    query fetchMembers($idUserGroup: UUID!) {
        members(condition: {idUserGroup: $idUserGroup, membershipStatus: WAITING}) {
            edges {
                node {
                    id
                    membershipStatus
                    userByIdUser {
                        id
                        email
                        firstname
                        lastname
                        idMainUserGroup
                    }
                }
            }
        }
    }
`

const deleteMember = () => gql`
    mutation deleteMember($id: UUID!) {
        deleteMember(input: {id: $id}) {
        clientMutationId
        deletedMemberNodeId
        }
    }
`

const findUserByEmail = () => gql`
query findUserByEmail($email: String) {
    users(condition: {email: $email}) {
      edges {
        node {
          id
          email
          firstname
          lastname
          notificationPeriodicity {
            days
            hours
            minutes
            months
            seconds
            years
          }
        }
      }
    }
  }
`

const findUsersByEmail = () => gql`
query findUserByEmail($search: String) {
  users(
    first: 4
    filter: {or: [{email: {includesInsensitive: $search}}, {firstname: {includesInsensitive: $search}}, {lastname: {includesInsensitive: $search}}]}
  ) {
    edges {
      node {
        id
        email
        firstname
        lastname
      }
    }
  }
}
`

const addMember = () => gql`
mutation addMember($idUser: UUID!, $idUserGroup: UUID!, $membershipStatus: TMembershipStatus = ACCEPTED) {
  createMember(
    input: {member: {idUser: $idUser, idUserGroup: $idUserGroup, membershipStatus: $membershipStatus}}
  ) {
    clientMutationId
  }
}
`

const manageMember = () => gql`
mutation MyMutation($membershipStatus: TMembershipStatus, $id: UUID!) {
  updateMember(input: {patch: {membershipStatus: $membershipStatus}, id: $id}) {
    clientMutationId
    member {
      id
      membershipStatus
    }
  }
}
`

const createRating = () => gql`
    mutation rateDocument($idUser: UUID!, $idDocument: UUID!, $idUserGroup: UUID!, $value: Int) {
        createRating(
            input: {rating: {idUser: $idUser, idUserGroup: $idUserGroup, idDocument: $idDocument, value: $value}}
        ) {
            clientMutationId
            rating {
                value
              }
        }
    }
`

const deleteRating = () => gql`
 mutation deleteRating($id: UUID!) {
  deleteRating(input: {id: $id}) {
    clientMutationId
    deletedRatingNodeId
  }
}
`

const updateRating = () => gql`
    mutation updateRating($id: UUID!, $value: Int) {
        updateRating(input: {patch: {value: $value}, id: $id}) {
            clientMutationId
        }
    }
`

const createUserGroup = () => gql`
mutation createUserGroup($name: String = "", $description: String = "", $visibility: TUserGroupVisibility, $idAdmin: UUID!) {
  createUserGroup(input: {userGroup: {name: $name, description: $description, visibility: $visibility, idAdmin: $idAdmin}}) {
    clientMutationId
    userGroup {
      id
    }
  }
}
`

const updateUserGroup = () => gql`
mutation MyMutation($id: UUID!, $name: String, $description: String) {
  updateUserGroup(
    input: {patch: {name: $name, description: $description}, id: $id}
  ) {
    clientMutationId
  }
}
`

const setGroupAdmin = () => gql`
mutation MyMutation($id: UUID!, $idAdmin: UUID!) {
  updateUserGroup(input: {patch: {idAdmin: $idAdmin}, id: $id}) {
    clientMutationId
  }
}
`

const updatePrivacy = () => gql`
mutation MyMutation($id: UUID!, $visibility: TUserGroupVisibility) {
  updateUserGroup(
    input: {patch: {visibility: $visibility}, id: $id}
  ) {
    clientMutationId
  }
}
`

const createGroupDocument = () => gql`
mutation MyMutation($idDocument: UUID!, $idUserGroup: UUID!) {
   createUserGroupsDocument(
    input: {userGroupsDocument: {idUserGroup: $idUserGroup, idDocument: $idDocument}}
  ) {
    clientMutationId
    documentByIdDocument {
      id
    }
  }
}
`

const sendShareInfo = () => gql`
mutation MyMutation($idDocument: UUID!, $idUser: UUID!, $idUserGroup: UUID!) {
  createSharing(
    input: {sharing: {idUser: $idUser, idUserGroup: $idUserGroup, idDocument: $idDocument}}
  ) {
    clientMutationId
  }
}

`

const fetchIfAlreadySaved = () => gql`
query getCommunityDocuments($idDocument: UUID!) {
  userGroupsDocuments(
    condition: {idDocument: $idDocument}
  ) {
    nodes {
      documentByIdDocument {
        id
        userGroupsDocumentsByIdDocument {
          nodes {
            idUserGroup
          }
        }
      }
    }
  }
}
`

const fetchIfSearchAlreadySaved = () => gql`
query MyQuery($idSearchHistory: UUID!) {
  userGroupsSearchHistories(condition: {idSearchHistory: $idSearchHistory}) {
    nodes {
      searchHistoryByIdSearchHistory {
        id
        userGroupsSearchHistoriesByIdSearchHistory {
          nodes {
            idUserGroup
          }
        }
      }
    }
  }
}
`
const fetchDocumentsByGroup = () => gql`
query fetchDoc($type: TDocumentType, $pIdUser: UUID!, $title: String, $pIdUserGroup: UUID!, $orderBy: [UserGroupsDocumentsOrderBy!]) {
  userGroupsDocuments(
    condition: {idUserGroup: $pIdUserGroup}
    orderBy: $orderBy
    filter: { documentType: {equalTo: $type }, documentTitle: {includesInsensitive: $title}} 
  ) {
    nodes {
      ratingAvg
      documentByIdDocument {
        id
        externalId
        engine
        documentType
        author
        title
        publicationDate
        hasUnreadMessages(pIdUser: $pIdUser, pIdUserGroup: $pIdUserGroup)
      }
    }
  }
}
`

const fetchQueriesByGroup = () => gql`
query fetchQueries($idUserGroup: UUID!, $searchQuery: String, $orderBy: [UserGroupsSearchHistoriesOrderBy!]) {
  userGroupsSearchHistories(
  condition: {idUserGroup: $idUserGroup}
  filter: {searchQuery: {includesInsensitive: $searchQuery}}
  orderBy: $orderBy
  ) {
    nodes {
      searchHistoryByIdSearchHistory {
        id
        searchEngine
        searchedQuery
      }
    }
  }
}
`

const findDocumentById = () => gql`
query MyQuery($id: UUID!) {
  document(id: $id) {
    externalId
    id
    title
  }
}
`

const createMessageStatus = () => gql`
    mutation createMessageStatus ($idUser: UUID!, $idUserGroup: UUID!, $idMessage: UUID!){
        createMessageStatus(
            input: {messageStatus: {idUser: $idUser, idUserGroup: $idUserGroup, idMessage: $idMessage, isRead: true}}
        ) {
            clientMutationId
        }
    }
`

const allUsers = () => gql`
query MyQuery {
    users {
      nodes {
        email
      }
    }
  }
`

const UPDATE_FAVORITE_COMMUNITY_BY_USER = gql`
  mutation updateFavoriteCommunityByUser ($idMainUserGroup: UUID, $id: UUID!) {
    updateUser(input: {patch: {idMainUserGroup: $idMainUserGroup}, id: $id}) {
      clientMutationId
    }
  }
`

const SEARCH_HISTORIES_BY_ID_USER = gql`
  query searchHistoriesByIdUser($idUser: UUID!) {
    searchHistories(condition: {idUser: $idUser}, orderBy: CREATED_AT_DESC, first: 100) {
      nodes {
        id
        searchEngine
        searchedQuery
        idUser
      }
    }
  }
`

const CREATE_SEARCH_HISTORY_BY_ID_USER = gql`
  mutation createSearchHistoryByIdUser($idUser: UUID!, $searchedQuery: JSON!, $searchEngine: TEngine) {
    createSearchHistory(input: {searchHistory: {searchedQuery: $searchedQuery, idUser: $idUser, searchEngine: $searchEngine}}) {
      searchHistory {
        id
        searchEngine
        searchedQuery
      }
    }
  }
`

const DELETE_SEARCH_HISTORY_BY_ID_USER = gql`
mutation deleteSearchHistory($id: UUID!) {
  deleteSearchHistory(input: {id: $id}) {
    clientMutationId
    deletedSearchHistoryNodeId
  }
}
`
const FETCH_FEED_ALL_GROUP_USER = gql`
  query getMyFeed($idUser: UUID!) {
    getMyFeed(pIdUser: $idUser, first: 50) {
      nodes {
          id
          event
          idDocument
          idSearchHistory
          userByIdUserEmitter {
            id
            firstname
            lastname
          }
          createdAt
          searchHistoryByIdSearchHistory {
            id
            searchedQuery
            searchEngine
            searchHistoryRatingsByIdSearchHistory {
              nodes {
                value
              }
            }
          }
          documentByIdDocument {
            id
            title
            engine
            externalId
            documentType
            publicationDate
            author
            abstract
            ratingsByIdDocument {
              nodes {
                value
                userByIdUser {
                  ratingsByIdUser (condition: {idUser: $idUser}, orderBy: ID_DESC) {
                    nodes {
                      value
                    }
                  }
                  membersByIdUser(condition: {membershipStatus: ACCEPTED}, filter: {}) {
                    nodes {
                      membershipStatus
                      idUser
                    }
                  }
                }
              }
            }
          } 
          idUser
          userByIdUser {
            id
            firstname
            lastname
          }
          idUserGroup
          userGroupByIdUserGroup {
            id
            name
            visibility
            description
          }
          content
        }
      }
    }
`

const FETCH_FEED_BY_ID_GROUP = gql`
  query fetchFeedByIdGroup($idUser: UUID, $id: UUID) {
    userGroups(condition: {id: $id }) {
      nodes {
        id
        name
        viewUserGroupFeedsByIdUserGroup (first: 50) {
          nodes {
            id
            event
            idDocument
            idSearchHistory
            userByIdUserEmitter {
              id
              firstname
              lastname
            }
            createdAt
            searchHistoryByIdSearchHistory {
              id
              searchedQuery
              searchEngine
              searchHistoryRatingsByIdSearchHistory {
                nodes {
                  value
                }
              }
            }
            documentByIdDocument {
              id
              title
              engine
              externalId
              publicationDate
              documentType
              abstract
              ratingsByIdDocument {
                nodes {
                  value
                  userByIdUser {
                    ratingsByIdUser (condition: {idUser: $idUser}, orderBy: ID_DESC) {
                      nodes {
                        value
                      }
                    }
                    membersByIdUser(condition: {membershipStatus: ACCEPTED}) {
                      nodes {
                        membershipStatus
                        idUser
                      }
                    }
                  }
                }
              }
            }
            idUser
            userByIdUser {
              id
              firstname
              lastname
            }
            idUserGroup
            userGroupByIdUserGroup {
              id
              name
              visibility
              description
            }
            content
          }
        }
      }
    }
  }
`

const getCommunitiesFollowingDocument = () => gql`
query getCommunitiesFollowingDocument($docId: UUID!) {
  documents(filter: {id: {equalTo: $docId}}) {
    nodes {
      userGroupsDocumentsByIdDocument {
        nodes {
          userGroupByIdUserGroup {
            id
            name
          }
        }
      }
    }
  }
}
`

const getCommunitiesFollowingQuery = () => gql`
query getCommunitiesFollowingQuery($id: UUID!) {
  searchHistories(filter: {id: {equalTo: $id}}) {
    nodes {
      userGroupsSearchHistoriesByIdSearchHistory {
        nodes {
          userGroupByIdUserGroup {
            id
            name
          }
        }
      }
    }
  }
}
`

const FETCH_MSG_BY_SEARCH_HISTORY = gql`
  query fetchMsgBySearchHistory ($idSearchHistory: UUID!, $idUserGroup: UUID!) {
    searchHistoryMessages(condition: {idSearchHistory: $idSearchHistory, idUserGroup: $idUserGroup}, orderBy: CREATED_AT_ASC) {
      nodes {
        content
        id
        createdAt
        idUser
        isRead
        userByIdUser {
          id
          firstname
          lastname
        }
      }
    }
  }
`

const CREATE_MSG_STATUS_HISTORY = gql`
  mutation createSearchHistoryMessageStatus ($idUser: UUID!, $idUserGroup: UUID!, $idMessage: UUID! ) {
    createSearchHistoryMessageStatus(
      input: {searchHistoryMessageStatus: {idUser: $idUser, idUserGroup: $idUserGroup, isRead: true, idMessage: $idMessage}}
    ) {
      clientMutationId
    }
  }
`

const CREATE_MSG_SEARCH_HISTORY = gql`
  mutation createMsgSearchHistory ($idUserGroup: UUID!, $idSearchHistory: UUID!,  $content: String!, $idUser: UUID!) {
    createSearchHistoryMessage(
      input: {searchHistoryMessage: {idUserGroup: $idUserGroup, idSearchHistory: $idSearchHistory, content: $content, idUser: $idUser}}
    ) {
      clientMutationId
    }
  }
`

const SEARCH_HISTORY_RATTINGS = gql`
  query searchHistoryRatings ($idUserGroup: UUID!, $idSearchHistory: UUID!, $idUser: UUID!) {
    searchHistoryRatings(condition: {idUserGroup: $idUserGroup, idSearchHistory: $idSearchHistory}) {
      nodes {
        id
        value
        idUser
        userByIdUser {
          id
          lastname
          firstname
          searchHistoryRatingsByIdUser(condition: {idUser: $idUser}, orderBy: ID_DESC) {
            nodes {
              id
              value
            }
          }
        }
      }
      totalCount
    }
  }
`

const CREATE_SEARCH_HISTORY_RATING = gql`
  mutation createSearchHistoryRating ($idUserGroup: UUID!, $idSearchHistory: UUID!, $idUser: UUID!, $value: Int!) {
    createSearchHistoryRating(
      input: {searchHistoryRating: {idUser: $idUser, idUserGroup: $idUserGroup, idSearchHistory: $idSearchHistory, value: $value}}
    ) {
      clientMutationId
        searchHistoryRating {
        value
      }
    }
  }
`

const DELETE_SEARCH_HISTORY_RATING = gql`
mutation deleteSearchHistoryRating ($id: UUID!) {
  deleteSearchHistoryRating(input: {id: $id}) {
    clientMutationId
    deletedSearchHistoryRatingNodeId
  }
}
`

const UPDATE_SEARCH_HISTORY_RATING = gql`
  mutation updateSearchHistoryRating ($value: Int!, $id: UUID!) {
    updateSearchHistoryRating(input: {patch: {value: $value}, id: $id}) {
      clientMutationId
    }
  }
`

const CREATE_SEARCH_HISTORY_SHARING_INFO = gql`
  mutation createSearchHistorySharing ($idUser: UUID!, $idUserGroup: UUID!, $idSearchHistory: UUID!) {
    createSearchHistorySharing(
      input: {searchHistorySharing: {idUser: $idUser, idUserGroup: $idUserGroup, idSearchHistory: $idSearchHistory}}
    ) {
      clientMutationId
    }
  }
`

const UPDATE_USER_NOTIFICATION = gql`
  mutation updateUser($id: UUID!, $notificationPeriodicity: IntervalInput) {
    updateUser(input: {patch: {notificationPeriodicity: $notificationPeriodicity}, id: $id}) {
      clientMutationId
    }
  }
`

const SEARCHHISTORY_BY_ID = gql`
  query searchHistory ($id: UUID!) {
    searchHistory(id: $id) {
      id
      idUser
      searchEngine
      searchedQuery
    }
  }
`

export default {
  fetchGroups,
  getGroupsByName,
  fetchMembers,
  fetchWaitingMembers,
  deleteMember,
  findUserByEmail,
  findUsersByEmail,
  addMember,
  manageMember,
  createUserGroup,
  setGroupAdmin,
  createGroupDocument,
  sendShareInfo,
  fetchIfAlreadySaved,
  fetchIfSearchAlreadySaved,
  fetchDocumentsByGroup,
  fetchQueriesByGroup,
  fetchMessagesByIdUserGroup,
  fetchRatingsByIdUserGroup,
  createMessage,
  upsertDocument,
  createRating,
  deleteRating,
  updateRating,
  findDocumentById,
  createMessageStatus,
  allUsers,
  updateUserGroup,
  updatePrivacy,
  UPDATE_FAVORITE_COMMUNITY_BY_USER,
  SEARCH_HISTORIES_BY_ID_USER,
  CREATE_SEARCH_HISTORY_BY_ID_USER,
  DELETE_SEARCH_HISTORY_BY_ID_USER,
  FETCH_FEED_BY_ID_GROUP,
  FETCH_MEMBERS_WAITING,
  getCommunitiesFollowingDocument,
  getCommunitiesFollowingQuery,
  FETCH_MSG_BY_SEARCH_HISTORY,
  CREATE_MSG_STATUS_HISTORY,
  CREATE_MSG_SEARCH_HISTORY,
  SEARCH_HISTORY_RATTINGS,
  FETCH_FEED_ALL_GROUP_USER,
  CREATE_SEARCH_HISTORY_RATING,
  UPDATE_SEARCH_HISTORY_RATING,
  DELETE_SEARCH_HISTORY_RATING,
  CREATE_SEARCH_HISTORY_SHARING_INFO,
  UPDATE_USER_NOTIFICATION,
  SEARCHHISTORY_BY_ID,
}
