import jwtDecode from 'jwt-decode'
import moment from 'moment'

export const getUserFromResponse = ({
  access_token: accessToken,
  refresh_token: refreshToken,
}) => {
  const { id, login, external: subscription } = jwtDecode(accessToken)
  return {
    id,
    login,
    accessToken,
    refreshToken,
    subscription,
  }
}

export const validateEmail = (email) => {
  const pattern = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/)
  return pattern.test(email)
}

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || (moment().isAfter(moment(exp * 1000)))
  }
  return true
}
