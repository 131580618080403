import React from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'

const FollowingGroups = ({
  groups,
  t,
}) => (
  <div className="followingGroups">
    <img src="/img/Icon-eye.svg" alt={t('isFollowed')} />
    <div className="followingGroups__tooltip">
      {groups.map(
        (group) => (
          <p>{group.userGroupByIdUserGroup.name}</p>
        ),
      )}
    </div>
  </div>
)

FollowingGroups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  t: PropTypes.func.isRequired,
}

export default FollowingGroups
