import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import StarRatings from 'react-star-ratings'
import { formatSearchQuery } from '../../lib/utils'

import '../../styles/app.scss'

const NewRateHistoryCard = ({ feed, activeTab, setHistoriesSearchValue }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    const date = new Date()
    if (dayjs(date).format('DD/MM/YY') === dayjs(feed.createdAt).format('DD/MM/YY')) {
      setInfoDate(t('today'))
      setDateCreated(dayjs(feed.createdAt).format('HH:mm'))
    } else if (
      (dayjs(date).subtract(1, 'day').format('DD/MM/YY')) === (dayjs(feed.createdAt).format('DD/MM/YY'))
    ) {
      setDateCreated(dayjs(feed.createdAt).format('HH:mm'))
      setInfoDate(t('yesterday'))
    } else {
      setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY'))
      setInfoDate('')
    }
  }, [])

  const formatEngine = (engine) => {
    switch (engine) {
      case 'PUB_MED':
        return 'pubmed'
      case 'EDS':
        return 'eds'
      case 'ESPACENET':
        return 'espacenet'
      case 'EBSCOHOST':
        return 'ebscohost'
      default:
        break
    }
  }

  const getUrl = () => {
    if (typeof feed.searchHistoryByIdSearchHistory.searchedQuery === 'object') {
      return `/searchresult?multisearch=${feed.searchHistoryByIdSearchHistory.id}&type=${feed.searchHistoryByIdSearchHistory.searchEngine ? formatEngine(feed.searchHistoryByIdSearchHistory.searchEngine) : 'pubmed'}`
    }
    return `/searchresult?value=${feed.searchHistoryByIdSearchHistory.searchedQuery}&history=${feed.searchHistoryByIdSearchHistory.id}`
  }

  return (
    <article className="rateHistoryCommunity">
      {activeTab !== 'selectCommunityActive' && (<p className="rateHistoryCommunity__title">{feed.userGroupByIdUserGroup.name}</p>)}
      <p className="rateHistoryCommunity__date">{`${infoDate} ${dateCreated}`}</p>

      <div className="rateHistoryCommunity__firstInfo">
        <div className="rateHistoryCommunity__user">
          <div className="rateHistoryCommunity__user--img" />
          <div style={{ display: 'flex', width: '82%' }}>
            <p
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
              {t('ratingHistory')}
              <span title={formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)} className="rateHistoryCommunity__user--searchQuery">{formatSearchQuery(feed.searchHistoryByIdSearchHistory.searchedQuery)}</span>
            </p>
          </div>
        </div>

        <div className="rateHistoryCommunity__ratting">
          <p className="rateHistoryCommunity__ratting--bold">{t('rating')}</p>
          <StarRatings
            rating={Number(feed.content) || 0}
            starRatedColor="purple"
            numberOfStars={5}
            starDimension="12px"
            starSpacing="0.3px"
          />
          <p className="rateHistoryCommunity__ratting--rate">
            {
            feed.searchHistoryByIdSearchHistory?.searchHistoryRatingsByIdSearchHistory
              ?.nodes[0]?.value ? (
              `(${feed.searchHistoryByIdSearchHistory?.searchHistoryRatingsByIdSearchHistory?.nodes[0]?.value.toFixed(1)})`
            ) : (`(${t('null')})`)
            }
          </p>
        </div>
      </div>

      <div className="rateDocCommunity__buttons">
        <button
          className="memberMessageCommunity__buttons--purple"
          type="button"
          onClick={() =>
            setHistoriesSearchValue(
              [feed.searchHistoryByIdSearchHistory],
            )}
        >
          {t('openChat')}
        </button>
        <Link
          alt="link file"
          to={getUrl}
        >
          {t('openSearch')}
        </Link>
      </div>
    </article>
  )
}

NewRateHistoryCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    documentByIdDocument: PropTypes.shape({
      engine: PropTypes.string,
      externalId: PropTypes.string,
      title: PropTypes.string,
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    searchHistoryByIdSearchHistory: PropTypes.shape({
      id: PropTypes.string,
      searchEngine: PropTypes.string,
      searchedQuery: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]),
      searchHistoryRatingsByIdSearchHistory: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({
          value: PropTypes.number,
        })),
      }),
    }),
  }),
  setHistoriesSearchValue: PropTypes.func.isRequired,
}
NewRateHistoryCard.defaultProps = {
  feed: {},
}
export default NewRateHistoryCard
