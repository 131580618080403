/* eslint-disable react/no-danger */
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import './styles/index.scss'

const NewMsgCard = ({ feed, activeTab, openFileInChat }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY - HH:mm'))
    setInfoDate('')
  }, [])

  return (
    <article className="memberMessageCommunity">
      {activeTab !== 'selectCommunityActive' && (<p className="memberMessageCommunity__title">{feed.userGroupByIdUserGroup.name}</p>)}
      <p className="memberMessageCommunity__date">{`${infoDate} ${dateCreated}`}</p>

      <div className="memberMessageCommunity__user">
        <div className="memberMessageCommunity__user--info">
          <div className="memberMessageCommunity__user--img" />
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '85%' }}>
            <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
            {t('sentNewMessage')}
            <strong title={feed.documentByIdDocument.title} style={{ textDecoration: 'underline' }} dangerouslySetInnerHTML={{ __html: feed.documentByIdDocument.title }} />
          </p>
        </div>

      </div>
      <div className="memberMessageCommunity__msgContainer">
        {feed.content.length < 300 ? (
          <p>{`" ${feed.content} "`}</p>
        ) : (
          <p>{`" ${feed.content.substring(0, 300)}... "`}</p>
        )}
      </div>
      <div className="memberMessageCommunity__buttons">
        <button
          className="memberMessageCommunity__buttons--purple"
          type="button"
          onClick={() => openFileInChat(feed)}
        >
          {t('openChat')}
        </button>
        <Link
          alt="link file"
          to={`/document/${feed.documentByIdDocument.engine.toLowerCase().replace('_', '')}/${feed.documentByIdDocument.externalId}?redirect=${window.location.pathname}?group=${feed.userGroupByIdUserGroup.id}&multi=${activeTab === 'communities' && true}`}
        >
          {t('openDoc')}
        </Link>
      </div>

    </article>
  )
}

NewMsgCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    documentByIdDocument: PropTypes.shape({
      engine: PropTypes.string,
      externalId: PropTypes.string,
      title: PropTypes.string,
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
  openFileInChat: PropTypes.func.isRequired,
}
NewMsgCard.defaultProps = {
  feed: {},
}
export default NewMsgCard
