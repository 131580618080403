import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../styles/app.scss'

const Header = () => {
  const { t } = useTranslation('header')

  return (
    <>
      <header className="header">
        <div className="header__title">
          <Link to="/">
            <img className="header__logo" src="/img/icon-metari.svg" alt="logo" />
          </Link>
        </div>
        <div className="header__nav">
          <Link to="/profile" className="header__linkLibrary">
            <p>{t('profile')}</p>
          </Link>
        </div>
      </header>
      <div style={{ height: 140 }} />
    </>
  )
}

export default Header
