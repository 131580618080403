const BASE_URL = {
  dev: 'https://lesaffre-search-dev.bettercalldave.io/',
  uat: 'https://lesaffre-search-uat.bettercalldave.io/',
  prod: 'https://metari.lesaffre.com/',
}

const GRAPHQL_URLS = {
  dev: 'https://lesaffre-search-dev.bettercalldave.io/graphql',
  uat: 'https://lesaffre-search-uat.bettercalldave.io/graphql',
  prod: 'https://metari.lesaffre.com/graphql',
}

const SocketParams = {
  dev: {
    webSocket: 'wss://lesaffre-search-dev.bettercalldave.io/',
    socketIoUrl: 'https://lesaffre-search-dev.bettercalldave.io/socket.io/socket.io.js',
  },
  uat: {
    webSocket: 'wss://lesaffre-search-uat.bettercalldave.io/',
    socketIoUrl: 'https://lesaffre-search-uat.bettercalldave.io/socket.io/socket.io.js',
  },
  prod: {
    webSocket: 'wss://metari.lesaffre.com/',
    socketIoUrl: 'https://metari.lesaffre.com/socket.io/socket.io.js',
  },
}

const LoginURL = {
  dev: 'https://lesaffre-search-dev.bettercalldave.io/ws/login',
  uat: 'https://lesaffre-search-uat.bettercalldave.io/ws/login',
  prod: 'https://metari.lesaffre.com/ws/login',
}

const { REACT_APP_INFRA = 'dev', REACT_APP_PREFIX = '' } = process.env
const API_BASE_URL = BASE_URL[REACT_APP_INFRA]
const GRAPHQL_URL = GRAPHQL_URLS[REACT_APP_INFRA]
const SOCKET = SocketParams[REACT_APP_INFRA]
const LOGIN = LoginURL[REACT_APP_INFRA]

export default {
  REACT_APP_INFRA,
  API_BASE_URL,
  REACT_APP_PREFIX,
  GRAPHQL_URL,
  SOCKET,
  LOGIN,
}
