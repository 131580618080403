/* eslint-disable react/no-danger */
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import './styles/index.scss'

const ShareDocCard = ({ feed, activeTab, openFileInChat }) => {
  const { t } = useTranslation('feedCommunities')
  const [dateCreated, setDateCreated] = useState()
  const [infoDate, setInfoDate] = useState('')

  useEffect(() => {
    setDateCreated(dayjs(feed.createdAt).format('DD/MM/YY - HH:mm'))
    setInfoDate('')
  }, [])
  return (
    <article className="sharingFileContainer">
      {activeTab !== 'selectCommunityActive' && (
        <p className="sharingFileContainer__title">{feed.userGroupByIdUserGroup.name}</p>
      )}
      <p className="sharingFileContainer__date">{`${infoDate} ${dateCreated}`}</p>
      <div className="sharingFileContainer__user">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="sharingFileContainer__user--img" />
          <p>
            <strong>{`${feed.userByIdUserEmitter.lastname} ${feed.userByIdUserEmitter.firstname}`}</strong>
            {t('sharedDoc')}
          </p>
        </div>

      </div>
      <div className="sharingFileContainer__fileContainer">
        <img src="/img/doc-icon.svg" alt="icon documentation" />
        <div>
          <p className="sharingFileContainer__fileContainer--file" dangerouslySetInnerHTML={{ __html: feed.documentByIdDocument.title }} />
          <p>{feed.documentByIdDocument.author}</p>
        </div>
      </div>
      <div className="sharingFileContainer__buttons">

        <button
          className="sharingFileContainer__buttons--purple"
          type="button"
          onClick={() => {
            openFileInChat(feed)
          }}
        >
          {t('openChat')}
        </button>
        <Link to={`/library?group=${feed.userGroupByIdUserGroup.id}&type=${feed.documentByIdDocument.documentType}`} alt="link library page">{t('goLibrary')}</Link>
        <Link
          alt="link file"
          to={`/document/${feed.documentByIdDocument.engine.toLowerCase().replace('_', '')}/${feed.documentByIdDocument.externalId}?redirect=${window.location.pathname}?group=${feed.userGroupByIdUserGroup.id}&multi=${activeTab === 'communities' && true}`}
        >
          {t('openDoc')}
        </Link>
      </div>
    </article>
  )
}

ShareDocCard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  feed: PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    userGroupByIdUserGroup: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    userByIdUserEmitter: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    documentByIdDocument: PropTypes.shape({
      engine: PropTypes.string,
      externalId: PropTypes.string,
      title: PropTypes.string,
      author: PropTypes.string,
      documentType: PropTypes.string,
    }),
  }),
  openFileInChat: PropTypes.func.isRequired,
}
ShareDocCard.defaultProps = {
  feed: {},
}
export default ShareDocCard
