/* eslint-disable react/no-danger */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../styles/app.scss'

const Result = ({
  setHistoriesSearchValue,
  type,
  result,
  setResultsSelected,
  resultsSelected,
  number,
  queries,
  querySelected,
}) => {
  const [encodeTitleEds, setEncodeTitleEds] = useState()
  const { t } = useTranslation('searchPage')

  const highlight = (text) => {
    const inputText = document.getElementById(result.id)
    if (inputText && !inputText.innerHTML.toLowerCase().includes(`<span class="highlight">${text.toLowerCase()}</span>`)) {
      const regex = new RegExp(`(${text})`, 'ig')
      inputText.innerHTML = inputText.innerHTML.replace(regex, '<span class="highlight">$1</span>')
    }
  }

  useEffect(() => {
    const regexp = /(?:.*)value=(.*?)(?:&.*|$)/
    const groups = regexp.exec(queries)
    const value = groups && groups[1]
    if (value) {
      highlight(value)
    }
    if (result.title && type === 'eds') {
      const titleEncode = encodeURI(result.title)
      setEncodeTitleEds(titleEncode)
    }
    if (querySelected) {
      querySelected.filter((e) => e.query).forEach((e) => highlight(e.query))
    }
  }, [result])

  return (
    <article className="result">
      {number !== 0 && (
        <p className="result__index">
          {number}
        </p>
      )}
      <input
        checked={resultsSelected.some((el) => el.id === result.id)}
        aria-label="check for selected this article"
        onChange={() => {
          if (resultsSelected.find((el) => el.id === result.id)) {
            setResultsSelected(resultsSelected.filter((el) => el.id !== result.id))
          } else {
            setResultsSelected([...resultsSelected, result])
            setHistoriesSearchValue([])
          }
        }}
        disabled={!result.id}
        name="result"
        className="result__input"
        type="checkbox"
      />
      {result.ISBN && (
        <img className="book-cover" src={`http://rps2images.ebscohost.com/rpsweb/othumb?id=NL$${result.id}$PDF&s=d`} alt={result.title} onError={(e) => { e.target.hidden = true }} />
      )}
      <div>
        {!result.titles && (
          <>
            {result.id ? (
              <Link to={`/document/${type}/${type === 'eds' ? encodeTitleEds : result.id}?redirect=${window.location.pathname + queries}`} alt="link file" className="result__title">
                <p id={result.id} dangerouslySetInnerHTML={{ __html: result.title || t('noTitle') }} />
              </Link>
            ) : (
              <div alt="link file" className="result__title">
                <p id={result.id} dangerouslySetInnerHTML={{ __html: result.title || t('noTitle') }} />
              </div>
            )}
          </>
        )}
        {result.titles && (
          <Link to={`/document/${type}/${result.id}?redirect=${window.location.pathname + queries}`} alt="link file" className="result__title">
            <p id={result.id}>{result.titles.en}</p>
          </Link>
        )}
        {result.authors && (
          <p className="result__author">{result.authors}</p>
        )}
        {result.author && (
          <p className="result__author">{result.author}</p>
        )}
        {result.ISBN && (
          <p className="result__identityFile">
            {`${moment(result.pubDate).format('YYYY-MM-DD')} - ISBN: ${result.ISBN}`}
          </p>
        )}
        {result?.doId && (
          <p className="result__identityFile">
            {`${moment(result.epubDate || result.pubDate).format('YYYY-MM-DD')} doi: ${result.doId} PMID: ${result.id} ${result.source}`}
          </p>
        )}
        {result?.abstracts && (
          <>
            <p className="result__identityFile">
              {`${moment(result.pubDate).format('YYYY-MM-DD')} - Publication Number: ${result.id}`}
            </p>
            <p className="result__abstract">{result.abstracts.en}</p>
          </>
        )}
        {!result?.abstracts && type === 'espacenet' && (
          <>
            <p className="result__identityFile">
              {`${moment(result.pubDate).format('YYYY-MM-DD')} - Publication Number: ${result.id}`}
            </p>
          </>
        )}
        {type === 'eds' && (
          <p className="result__identityFile">
            {`${moment(result.pubDate).format('YYYY-MM-DD')} - doi: ${result.id} - ${result.edition} - ISSN: ${result.ISSN}`}
          </p>
        )}
      </div>
    </article>
  )
}

Result.propTypes = {
  result: PropTypes.objectOf(PropTypes.any).isRequired,
  setResultsSelected: PropTypes.func.isRequired,
  resultsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  number: PropTypes.number,
  type: PropTypes.string.isRequired,
  queries: PropTypes.string.isRequired,
  setHistoriesSearchValue: PropTypes.func.isRequired,
  querySelected: PropTypes.arrayOf(PropTypes.shape({})),
}

Result.defaultProps = {
  number: 0,
  querySelected: null,
}

export default Result
