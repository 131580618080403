import { useMutation } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import gql from '../../graphql'
import './styles/index.scss'

const History = ({ onSearch, close, history }) => {
  const { t } = useTranslation('resultsPage')
  const accessToken = localStorage.getItem('token')
  const decoded = jwtDecode(accessToken)
  const { id } = decoded
  const [historicResults, setHistoricResults] = useState([])

  useEffect(() => {
    if (history.searchHistories.nodes) {
      const histories = history.searchHistories.nodes
      const data = []
      histories.forEach((item) => {
        if (typeof item.searchedQuery === 'object') {
          let querys = ''
          for (const queryInArray of item.searchedQuery) {
            if (queryInArray.query) {
              if (queryInArray.operator) {
                querys += `${queryInArray.operator} ${
                  queryInArray.keyField === 'all'
                    ? ''
                    : `${queryInArray.keyField}:`
                }${queryInArray.query} `
              } else {
                querys += `${
                  queryInArray.keyField === 'all'
                    ? ''
                    : `${queryInArray.keyField}:`
                }${queryInArray.query} `
              }
            } else if (queryInArray.dates) {
              if (queryInArray.operator) {
                querys += `${queryInArray.operator} ${queryInArray.keyField}:${queryInArray.dates[0]}/${queryInArray.dates[1]} `
              } else {
                querys += `${queryInArray.keyField}:${queryInArray.dates[0]}/${queryInArray.dates[1]} `
              }
            }
          }
          data.push({
            id: item.id,
            searchEngine: item.searchEngine,
            multiSearched: item.searchedQuery,
            searchedQuery: querys,
            multi: true,
          })
        } else {
          data.push(item)
        }
      })
      const filteredData = data
        .filter((v, i, a) => a.findIndex((v2) =>
          (v2.searchedQuery.trim() === v.searchedQuery.trim())) === i)

      setHistoricResults(filteredData)
    }
  }, [history])

  const [createHistory] = useMutation(gql.CREATE_SEARCH_HISTORY_BY_ID_USER, {
    onCompleted: (data) => {
      onSearch(data.createSearchHistory.searchHistory)
    },
  })

  const [deleteHistory] = useMutation(gql.DELETE_SEARCH_HISTORY_BY_ID_USER)

  const searchHistory = (searchValue) => {
    createHistory({
      variables: {
        idUser: id,
        searchedQuery: searchValue.multiSearched || searchValue.searchedQuery,
        searchEngine: searchValue.searchEngine,
      },
    })
    deleteHistory({
      variables: {
        id: searchValue.id,
      },
    })
  }

  return (
    <div className="historyContainer">
      <div className="historyContainer__header">
        <div className="historyContainer__title">
          <button type="button" onClick={() => close()}>
            <img src="/img/picto-historique-violet.svg" alt="icon search" />
          </button>
          <p>{t('searchHistoric.title')}</p>
        </div>
        <button
          className="historyContainer--close"
          type="button"
          onClick={() => close()}
        >
          <img
            src="/img/picto-close.svg"
            alt="icon close"
            style={{ width: '16px' }}
          />
        </button>
      </div>
      <ul className="historyContainer__resultsHistories">
        {historicResults &&
          historicResults.map((searchValue) => (
            <li
              key={searchValue.id}
              className="historyContainer__resultOneHistory"
            >
              <img
                style={{ opacity: '0.5' }}
                src="/img/picto_material-access-time.svg"
                alt="icon search"
              />
              <button type="button" onClick={() => searchHistory(searchValue)}>
                {searchValue.searchedQuery}
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default History

History.propTypes = {
  onSearch: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.shape({
    searchHistories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
}
