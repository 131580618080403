import React, { useState } from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const BrowseCommunities = ({
  selectCommunity,
  setCreateCommunity,
  setBrowseSearch,
  browseSearch,
  userCommunities,
  communitySelected,
  changeTab,
  activeTab,
  setOpenUpdateCommunity,
  setOpenCollaboration,
  setFileSelected,
  setDetailCommunityOpen,
}) => {
  const [communityName, setCommunityName] = useState('')
  const [searchState, setSearchState] = useState('communities')

  const displayedCommunities = userCommunities
    ?.filter((community) => community.userGroupByIdUserGroup.name.toLowerCase()
      .includes(communityName.toLowerCase())) || []

  const { t } = useTranslation('community')

  const changeTabActive = (tab) => {
    setCommunityName('')
    setFileSelected([])
    switch (tab) {
      case 'communities':
        setSearchState('communities')
        selectCommunity(null)
        setBrowseSearch('')
        changeTab('communities')
        setOpenCollaboration(false)
        break
      case 'browse':
        selectCommunity(null)
        setCommunityName('')
        changeTab('browse')
        setOpenCollaboration(false)
        setSearchState('browse')
        break
      default:
        break
    }
  }

  const changeGroupSelected = (group) => {
    setFileSelected([])
    setSearchState('communities')
    setOpenCollaboration(false)
    setDetailCommunityOpen(false)
    selectCommunity(group)
    changeTab('selectCommunityActive')
    setOpenUpdateCommunity(true)
  }

  return (
    <div className="browseCommunities">
      <div className="inputIcon">
        <img src="/img/picto_search.svg" alt="" />
        <input
          type="text"
          placeholder={searchState === 'communities' ? t('searchCommunity') : t('browseCommunity')}
          value={searchState === 'communities' ? communityName : browseSearch}
          onChange={(e) => (searchState === 'communities' ? setCommunityName(e.target.value) : setBrowseSearch(e.target.value))}
        />
      </div>
      <button
        type="button"
        className={`browseCommunities__communitiesBtn${searchState === 'browse' ? '--active' : ''}`}
        onClick={() => {
          changeTabActive('browse')
        }}
      >
        <img src={`${searchState === 'browse' ? '/img/compass2.svg' : '/img/compass.svg'}`} alt="" />
        {t('browse')}
      </button>
      <button
        type="button"
        className={`browseCommunities__communitiesBtn${searchState === 'communities' ? '--active' : ''}`}
        onClick={() => {
          changeTabActive('communities')
        }}
      >
        <img src={`${searchState === 'communities' ? '/img/picto-community2.svg' : '/img/picto-community.svg'}`} alt="" />
        {t('communities')}
      </button>
      <div className="browseCommunities__communitiesBtn--border" />
      <div className="browseCommunities__itemContainer">
        {displayedCommunities.map((group) => (
          <div key={group.userGroupByIdUserGroup.id}>
            {group.userByIdUser.idMainUserGroup === group.userGroupByIdUserGroup.id && (
              <button
                style={{
                  backgroundColor: '#F9F8F7',
                }}
                className={activeTab === 'selectCommunityActive' && communitySelected?.userGroupByIdUserGroup?.id === group.userGroupByIdUserGroup.id ? 'browseCommunities__listItem--selected' : 'browseCommunities__listItem'}
                type="button"
                onClick={() => {
                  if (communitySelected === group) {
                    selectCommunity(null)
                    changeTab('communities')
                  } else {
                    changeGroupSelected(group)
                  }
                }}
              >
                {group.userGroupByIdUserGroup.visibility === 'RESTRICTED' && (
                <img src="img/picto-lock.svg" alt="picto lock" />
                )}
                {group.userGroupByIdUserGroup.name}
                {group.userByIdUser.idMainUserGroup === group.userGroupByIdUserGroup.id && (
                <>
                  <img className="browseCommunities__itemContainer--fav" src="/img/star-purple.svg" alt={t('setMain')} />
                </>
                )}
              </button>
            )}
          </div>
        ))}
        {displayedCommunities.map((group) => (
          <div key={group.userGroupByIdUserGroup.id}>
            {group.userByIdUser.idMainUserGroup !== group.userGroupByIdUserGroup.id && (
              <button
                className={activeTab === 'selectCommunityActive' && communitySelected?.userGroupByIdUserGroup?.id === group.userGroupByIdUserGroup.id ? 'browseCommunities__listItem--selected' : 'browseCommunities__listItem'}
                key={group.userGroupByIdUserGroup.id}
                type="button"
                onClick={() => {
                  if (communitySelected?.id === group?.id) {
                    selectCommunity(null)
                    changeTab('communities')
                  } else {
                    changeGroupSelected(group)
                  }
                }}
              >
                {group.userGroupByIdUserGroup.visibility === 'RESTRICTED' && (
                  <img src="img/picto-lock.svg" alt="picto lock" />
                )}
                {group.userGroupByIdUserGroup.name}
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="browseCommunities__newBtn flex">
        <button
          className="lightpurple-btn-outline"
          type="button"
          onClick={() => {
            setCreateCommunity()
            selectCommunity(null)
          }}
        >
          {`+ ${t('newCommunity')}`}
        </button>
      </div>
    </div>
  )
}

export default BrowseCommunities

BrowseCommunities.propTypes = {
  selectCommunity: PropTypes.func.isRequired,
  setCreateCommunity: PropTypes.func.isRequired,
  userCommunities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCommunity: PropTypes.shape({
    id: PropTypes.string,
  }),
  setBrowseSearch: PropTypes.func.isRequired,
  browseSearch: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  setOpenUpdateCommunity: PropTypes.func.isRequired,
  setOpenCollaboration: PropTypes.func.isRequired,
  communitySelected: PropTypes.objectOf(PropTypes.any),
  activeTab: PropTypes.string.isRequired,
  setFileSelected: PropTypes.func.isRequired,
  setDetailCommunityOpen: PropTypes.func.isRequired,
}

BrowseCommunities.defaultProps = {
  selectedCommunity: null,
  communitySelected: null,
}
