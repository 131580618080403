import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import jwt from 'jwt-decode'
import { isUserTokenExpired } from '../../lib/helpers'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token')
  let redirectPath = ''
  if (!token) {
    localStorage.setItem('url', window.location.pathname + window.location.search)
    redirectPath = '/login'
  } else {
    const { exp } = jwt(token)
    redirectPath = moment().isAfter(moment(exp * 1000)) ? '/login' : '/'
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        localStorage.getItem('token') && !isUserTokenExpired(token) ? <Component {...props} /> : <Redirect to={{ pathname: `${redirectPath}` }} />
      )}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]),
}

PrivateRoute.defaultProps = {
  component: null,
}

export default PrivateRoute
