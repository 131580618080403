import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import i18n from 'i18next'
import React from 'react'
import { render } from 'react-dom'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import App from './App'
import config from './config'
import resources from './i18n'
import { isUserTokenExpired } from './lib/helpers'
import * as serviceWorker from './serviceWorker'

let environment = 'development'
if (config.REACT_APP_INFRA === 'uat') {
  environment = 'staging'
} else if (config.REACT_APP_INFRA === 'prod') {
  environment = 'production'
}

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
})
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  if (!token) {
    return {
      headers,
    }
  }
  if (token && isUserTokenExpired(token)) {
    localStorage.removeItem('token')
    window.location.href = '/login'
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const client = new ApolloClient({
  connectToDevTools: config.REACT_APP_INFRA === 'dev',
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('key') || 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  })

Sentry.init({
  dsn: 'https://cf5f7fd553b941c58247b28dceae10b4@o530648.ingest.sentry.io/6781100',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment,
})

render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
